import Breadcrumbs from "components/Common/Breadcrumb"
import debounce from "debounce-promise"
import ModalTransferInvoices from "pages/ProfessionalPaymentTransfer/ComponentsInvoices/ModalTransferInvoices"
import TransferNotesColumns from "pages/ProfessionalPaymentTransfer/ListTransferInvoices/TransferNotesColumns"
import React, { useMemo, useRef, useState } from "react"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
    PaginationListStandalone,
    PaginationProvider,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import { useDispatch, useSelector } from "react-redux"
import { Link, useHistory } from "react-router-dom"
import Select from "react-select"
import AsyncSelect from "react-select/async"
import {
    Button,
    Card,
    CardBody,
    Col,
    Container,
    FormGroup,
    Input,
    Label,
    Row,
    Spinner,
} from "reactstrap"
import { search as patientServiceSearch } from "services/vitta-core/patient"
import { search as professionalServiceSearch } from "services/vitta-core/professional"
import { createInvoicesPayments } from "services/vitta-core/professional-transfer-invoice-payments"
import {
    cancelInvoice,
    downloadInvoiceNfsePdf,
    generateInvoicesNfse,
    search as searchTransferNotes,
} from "services/vitta-core/professional-transfer-invoices"
import { showSweetAlertDialog, showToast } from "store/layout/actions"
import PROF_TRANSFER_INVOICE_FISCAL_STATUS from "utils/consts/prof-transfer-invoice-fiscal-status"
import PROF_TRANSFER_INVOICE_STATUS from "utils/consts/prof-transfer-invoice-status"
import GenerateNfseButton from "./components/GenerateNfseButton"

const ListTransferInvoices = () => {
    const dispatch = useDispatch()
    const history = useHistory()

    const [generatingNfse, setGeneratingNfse] = useState(false)

    const [transferNotesList, setTransferNotesList] = useState({
        page: 1,
        sizePerPage: 20,
        totalSize: 20,
        custom: true,
        data: [],
    })

    const Scope = useSelector(state => state.scope)

    const today = new Date().toLocaleDateString("pt-BR")
    const todayOnlyDate =
        today.substr(6, 4) + "-" + today.substr(3, 2) + "-" + today.substr(0, 2)

    const inputUnit = useRef()
    const inputPatient = useRef()
    const inputStartCreatedAt = useRef()
    const inputProfessional = useRef()
    const inputStatus = useRef()
    const inputFiscalStatus = useRef()
    const componentRef = useRef()
    const userAuth = useSelector(state => state.auth.access?.user)

    const defaultPageControl = {
        searching: false,
    }

    const [canGen, setCanGen] = useState(false)
    const [isCheck, setIsCheck] = useState([])
    const [invoiceStatus, setInvoiceStatus] = useState(
        PROF_TRANSFER_INVOICE_STATUS.OPEN
    )
    const [modalTransferInvoiceData, setModalTransferInvoiceData] = useState({
        show: false,
        data: null,
    })
    const [pageControl, setPageControl] = useState(defaultPageControl)

    const handleOnSelect = (row, isSelect) => {
        if (isSelect) {
            setIsCheck([...isCheck, row.id])
        } else {
            setIsCheck(isCheck.filter(item => item !== row.id))
        }
        return true // return true or dont return to approve current select action
    }

    const handleOnSelectAll = (isSelect, rows) => {
        if (isSelect) {
            setIsCheck(rows.map(i => i.id))
        } else {
            setIsCheck([])
        }
    }

    const selectRow = {
        mode: "checkbox",
        clickToSelect: true,
        selected: isCheck,
        onSelect: handleOnSelect,
        onSelectAll: handleOnSelectAll,
    }

    const search = async (filter, page) => {
        console.log("start searching ", pageControl.searching)

        // if (pageControl.searching) return; //tem algum bug que está colocando com true

        let sizePerPage = 10

        try {
            console.log("searching true")
            setPageControl({ ...pageControl, ...{ searching: true } })
            setTransferNotesList({
                page: page,
                sizePerPage: sizePerPage,
                totalSize: 0,
                custom: true,
                data: [],
            })

            let record = await searchTransferNotes(filter, page, sizePerPage)

            setTransferNotesList({
                page: page,
                sizePerPage: sizePerPage,
                totalSize: record.total_pages * sizePerPage,
                custom: true,
                data: record.data,
            })
        } catch (e) {
            console.log("error", e)
        }

        setPageControl({ ...pageControl, ...{ searching: false } })
        console.log("searching false ", pageControl.searching)
    }

    // eslint-disable-next-line no-unused-vars
    const onTableChange = (a, b, c) => {
        if (a == "pagination") {
            searchOptions(b.page)
        }
    }

    const unitsOptions = useMemo(() => {
        if (!userAuth?.units) return []

        let options_list = []
        /*
        let options_list = [
            {
                label: "Todas Unidades",
                value: null,
            },
        ]*/
        userAuth.units.forEach(element => {
            options_list.push({ label: element.name, value: element.id })
        })
        return options_list
    }, [userAuth])

    const searchOptions = (page = 1) => {
        let searchObj = {}
        let unit_id = inputUnit?.current?.state?.value?.value
        let patient_id = inputPatient?.current?.select?.state?.value?.value
        let professional_id =
            inputProfessional?.current?.select?.state?.value?.value
        let exec_at = inputStartCreatedAt.current.value
        let status = inputStatus.current?.state?.value?.value
        let fiscal_status = inputFiscalStatus.current?.state?.value?.value

        setCanGen(true)
        setIsCheck([])

        if (status) searchObj.status = status
        if (fiscal_status) searchObj.fiscal_status = fiscal_status
        if (unit_id) searchObj.unit_id = unit_id
        if (patient_id) searchObj.patient_id = patient_id
        if (exec_at) searchObj.exec_at = exec_at
        if (professional_id) searchObj.professional_id = professional_id

        /*if(!professional_id)
        {
          return dispatch(
            showSweetAlertDialog({
                title: "Ops... ",
                text: "Informe o profissional no filtro.",
                type: "error",
                showConfirm: true
            })
          )
        }*/

        searchObj.expand_payment = true

        search(searchObj, page)
    }

    const renderNoDataIndication = () => {
        if (pageControl.searching)
            return (
                <Row align="center" className="mt-5 mb-5">
                    <Col sm="12" className="mt-5 mb-5">
                        <div
                            className="spinner-border text-primary"
                            role="status"
                        >
                            <span className="sr-only">Loading...</span>
                        </div>
                    </Col>
                </Row>
            )
        else {
            return (
                <Row className="mt-3 mb-3">
                    <Col sm="12">
                        Não há registros que correspondam com a sua busca.
                    </Col>
                </Row>
            )
        }
    }
    const loadPatientInputOptions = async inputValue => {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await patientServiceSearch(
                    { term: inputValue, fields:"id,name,cpf"  },
                    1,
                    20
                )

                let patients_values = []

                response.data.forEach(element => {
                    patients_values.push({
                        label:
                            `${element.id} - ` +
                            element.name +
                            (element.cpf ? ` <${element.cpf}>` : ""),
                        value: element.id,
                    })
                })

                return resolve(patients_values)
            } catch (e) {
                console.error(e)
                return reject([])
            }
        })
    }
    const debounceOptionsPatient = debounce(loadPatientInputOptions, 600)
    const loadProfessionalInputOptions = async inputValue => {
        return new Promise(async (resolve, reject) => {
            try {
                const statusActive = 1
                let response = await professionalServiceSearch(
                    { term: inputValue, status: statusActive },
                    1,
                    20
                )

                let professional_values = []

                response.data.forEach(element => {
                    professional_values.push({
                        label:
                            `${element.id} - ` +
                            element.name +
                            (element.cpf ? ` <${element.cpf}>` : ""),
                        value: element.id,
                    })
                })

                return resolve(professional_values)
            } catch (e) {
                console.error(e)
                return reject([])
            }
        })
    }
    const debounceOptionsProfessional = debounce(
        loadProfessionalInputOptions,
        600
    )

    const PayInvoice = async () => {
        dispatch(
            showSweetAlertDialog({
                title: "Atenção ",
                text:
                    "Tem certeza que deseja realizar o pagamento das Notas de Repasse selecionadas?",
                type: "warning",
                onConfirmAction: () => {
                    confirmPayInvoices()
                },
                showCancel: true,
                showConfirm: true,
                cancelBtnText: "Não",
                confirmBtnText: "Sim",
            })
        )
    }

    const confirmPayInvoices = async () => {
        try {
            let unit_id = inputUnit?.current?.state?.value?.value
            if (isCheck.length == 0) return

            let data = {
                prof_transfer_invoice_ids: isCheck,
                unit_id: unit_id,
            }
            await createInvoicesPayments(data)

            dispatch(
                showToast({
                    title: "Sucesso",
                    text: "Pagamentos realizados com sucesso!",
                    type: "success",
                })
            )

            searchOptions(transferNotesList.page)
        } catch (e) {
            let title = "Ops... "
            let textError =
                "Houve um erro ao processar sua solicitação, tente novamente mais tarde..."

            if (e?.response?.status == 403) {
                textError =
                    "Você não possui permissões para realizar essa operação. Solicite ao seu Gestor."
            } else if (e?.response?.data) {
                switch (e.response.data.error_code) {
                    case "prof_transfer_invoice_exists":
                        textError =
                            "Já existe nota de repasse para essa OS nesta data de execução."
                        break
                        case "business_partner_not_found_in_vittapag":
                            title = "Parceiro de negócio não encontrado!"
                            textError =
                                "Parceiro de negócio não encontrado em vittá pagamentos, verifique e tente novamente."
                            break
                }
            }

            dispatch(
                showSweetAlertDialog({
                    title: title,
                    text: textError,
                    type: "error",
                    showConfirm: true,
                })
            )
        }
    }

    const confirmGenerateNfse = () => {
        dispatch(
            showSweetAlertDialog({
                title: "Atenção ",
                text:
                    "Deseja realmente emitir as notas fiscais de todas essas notas de repasse?",
                type: "warning",
                onConfirmAction: () => {
                    generateNfse()
                },
                showCancel: true,
                showConfirm: true,
                cancelBtnText: "Não",
                confirmBtnText: "Sim",
            })
        )
    }

    const generateNfse = async () => {
        try {
            if (isCheck.length == 0) return

            setGeneratingNfse(true)

            let unit_id = inputUnit?.current?.state?.value?.value

            let data = {
                prof_transfer_invoice_ids: [...isCheck],
                unit_id: unit_id,
            }

            const result = await generateInvoicesNfse(data)

            if (
                !result ||
                !result.error ||
                !Array.isArray(result.error) ||
                !result.success ||
                !Array.isArray(result.success)
            )
                throw ""

            const errors = result.error
            const success = result.success

            if (errors.length && !success.length) {
                dispatch(
                    showToast({
                        title: "Erro",
                        text:
                            "Nenhuma nota fiscal foi transmitida com sucesso.",
                        type: "error",
                    })
                )
            } else if (errors.length && success.length) {
                dispatch(
                    showToast({
                        title: "Sucesso",
                        text:
                            "Operação realizada com sucesso. Porém, a emissão de algumas notas falharam.",
                        type: "warning",
                    })
                )
            } else if (!errors.length && success.length) {
                dispatch(
                    showToast({
                        title: "Sucesso",
                        text: "Notas transmitidas com sucesso!",
                        type: "success",
                    })
                )
            } else if (!errors.length && !success.length) {
                dispatch(
                    showToast({
                        title: "Sucesso",
                        text:
                            "Nenhuma nota fiscal estava com situação fiscal em aberto ou habilitada para ser transmitida para a prefeitura.",
                        type: "warning",
                    })
                )
            }

            searchOptions(transferNotesList.page)
        } catch (e) {
            let textError =
                "Houve um erro ao tentar processar as notas fiscais. Tente novamente mais tarde."

            if (e?.response?.status == 403) {
                textError =
                    "Você não possui permissões para realizar essa operação. Solicite ao seu Gestor."
            } else if (e?.response?.data) {
                switch (e.response.data.error_code) {
                    case "missing_ids":
                        textError =
                            e.response.data.error_message ??
                            "Escolha pelo menos um repasse médico para enviar as notas fiscais"
                        break

                    case "missing_unit":
                        textError =
                            e.response.data.error_message ??
                            "A unidade é obrigatória!"
                        break
                }
            }

            dispatch(
                showSweetAlertDialog({
                    title: "Ops... ",
                    text: textError,
                    type: "error",
                    showConfirm: true,
                })
            )
        } finally {
            setGeneratingNfse(false)
        }
    }

    const fiscalStatusBadge = fiscal_status => {
        switch (fiscal_status) {
            case PROF_TRANSFER_INVOICE_FISCAL_STATUS.OPEN:
                return { label: "Aberta", value: 1 }

            case PROF_TRANSFER_INVOICE_FISCAL_STATUS.ISSUED:
                return { label: "Emitida", value: 2 }

            case PROF_TRANSFER_INVOICE_FISCAL_STATUS.TRANSMITTING:
                return {
                    label: "Transmitindo",
                    value: 3,
                }

            case PROF_TRANSFER_INVOICE_FISCAL_STATUS.FAILED:
                return {
                    label: "Falha",
                    value: 4,
                }

            case PROF_TRANSFER_INVOICE_FISCAL_STATUS.REJECTED: {
                return {
                    label: "Rejeitada",
                    value: 5,
                }
            }

            case PROF_TRANSFER_INVOICE_FISCAL_STATUS.CANCELLED: {
                return {
                    label: "Cancelada",
                    value: 6,
                }
            }

            case PROF_TRANSFER_INVOICE_FISCAL_STATUS.REPLACED: {
                return {
                    label: "Substituída",
                    value: 7,
                }
            }

            default: {
                return {
                    label: "",
                    value: null,
                }
            }
        }
    }

    const ModalInvoices = data => {
        let items = []
       
        let total = 0
        let totalModal = 0

        for (let i = 0; i < data?.items?.length; i++) {
            items.push({
                item_id: {
                    label: data?.items[i].item.name,
                    value: data?.items[i].item.id,
                },
                quantity: data?.items[i].quantity,
                price_table: data?.items[i].price_table / 100,
                price: data?.items[i].price / 100,
            })
            let useItem = data.items[i]
            total += useItem.quantity * (useItem.price ? useItem.price : 0)

            totalModal =
                "R$ " +
                new Intl.NumberFormat("pt-br", {
                    style: "decimal",
                    currency: "BRL",
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                }).format(total / 100)
        }

        const dataRow = {
            note_id: data?.id,
            os_id: data?.os_id,
            patient_id: {
                label: data?.patient?.name,
                value: data?.patient?.id,
            },
            cpf_patient: data?.patient?.cpf,
            address_patient: data?.patient?.addr_address,
            district_patient: data?.patient?.addr_district,
            complement_patient: data?.patient?.addr_complement,
            cep_patient: data?.patient?.addr_cep,
            state_patient: data?.patient?.addr_state,
            county_patient: {
                label: data?.patient?.county?.name,
                value: data?.patient?.county?.id,
            },
            professional_id: {
                label: data?.professional?.name,
                value: data?.professional?.id,
            },
            cpf_professional: data?.professional?.cpf,
            cnpj_professional: data?.professional?.cnpj,
            data_exec: data?.exec_at,
            created_at:
                data?.created_at?.substr(0, 4) +
                "-" +
                data?.created_at?.substr(5, 2) +
                "-" +
                data?.created_at?.substr(8, 2),
            items: items,
            status_fiscal: fiscalStatusBadge(data.fiscal_status),
            status: data?.status,
            prof_transfer_payment_id: data?.prof_transfer_invoice_payment?.id,
            unit_name: data?.unit?.name,
            totalModal: totalModal,
            prof_transfer_invoice_payment:data?.prof_transfer_invoice_payment
        }

        if (!dataRow) {
            dispatch(
                showSweetAlertDialog({
                    title: "Ops... ",
                    text: textError,
                    type: "error",
                    showConfirm: true,
                })
            )
            return
        }

        setModalTransferInvoiceData({
            show: true,
            data: dataRow,
        })
    }

    const LoadingNfse = () => {
        if (!generatingNfse) return <></>

        return (
            <div
                style={{
                    zIndex: 100,
                    width: "100wh",
                    height: "100vh",
                    backgroundColor: "#00000057",
                    flex: 1,
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                }}
            >
                <Spinner>Loading...</Spinner>
            </div>
        )
    }

    return (
        <React.Fragment>
            <LoadingNfse />
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs
                        title="Listagem"
                        breadcrumbItem="Notas de Repasse"
                    />
                    <Row>
                        <Col xs="12">
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col sm="3">
                                            <FormGroup>
                                                <Label>Unidade</Label>
                                                <Select
                                                    name={`unit_id`}
                                                    placeholder="Selecione a Unidade"
                                                    classNamePrefix="select2-selection"
                                                    options={unitsOptions}
                                                    isMulti={false}
                                                    isClearable
                                                    ref={inputUnit}
                                                    onChange={e => {
                                                        setCanGen(false) //mudou algo tem que buscar novamente
                                                    }}
                                                    defaultValue={
                                                        Scope.unit
                                                            ? {
                                                                  label: Scope.unit.name?.substr(
                                                                      0,
                                                                      40
                                                                  ),
                                                                  value:
                                                                      Scope.unit
                                                                          .id,
                                                              }
                                                            : null
                                                    }
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col sm="3">
                                            <FormGroup>
                                                <Label>Paciente</Label>
                                                <AsyncSelect
                                                    name="patient_id"
                                                    placeholder="Selecione o paciente"
                                                    classNamePrefix="select2-selection"
                                                    defaultOptions
                                                    isClearable
                                                    cacheOptions
                                                    loadOptions={
                                                        debounceOptionsPatient
                                                    }
                                                    noOptionsMessage={() =>
                                                        "Sem Resultados. Digite para Buscar"
                                                    }
                                                    ref={inputPatient}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col sm="3">
                                            <FormGroup>
                                                <Label>Profissional</Label>
                                                <AsyncSelect
                                                    name="professional_id"
                                                    placeholder="Selecione o professional"
                                                    classNamePrefix="select2-selection"
                                                    defaultOptions
                                                    isClearable
                                                    cacheOptions
                                                    loadOptions={
                                                        debounceOptionsProfessional
                                                    }
                                                    noOptionsMessage={() =>
                                                        "Sem Resultados. Digite para Buscar"
                                                    }
                                                    ref={inputProfessional}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col sm="3">
                                            <FormGroup>
                                                <Label>Data Execução </Label>
                                                <Input
                                                    type="date"
                                                    innerRef={
                                                        inputStartCreatedAt
                                                    }
                                                    defaultValue={todayOnlyDate}
                                                ></Input>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm="3">
                                            <FormGroup>
                                                <Label>Status Nota</Label>

                                                <Select
                                                    ref={inputStatus}
                                                    defaultValue={{
                                                        label: "Aberta",
                                                        value:
                                                            PROF_TRANSFER_INVOICE_STATUS.OPEN,
                                                    }}
                                                    onChange={e => {
                                                        // if (
                                                        //     e.value !=
                                                        //     PROF_TRANSFER_INVOICE_STATUS.AVAILABLE_FOR_PAYMENT
                                                        // )
                                                        setCanGen(false)

                                                        setInvoiceStatus(
                                                            e.value
                                                        )
                                                    }}
                                                    options={[
                                                        {
                                                            label:
                                                                "Disponível para pagamento",
                                                            value:
                                                                PROF_TRANSFER_INVOICE_STATUS.AVAILABLE_FOR_PAYMENT,
                                                        },
                                                        {
                                                            label: "Aberta",
                                                            value:
                                                                PROF_TRANSFER_INVOICE_STATUS.OPEN,
                                                        },
                                                        {
                                                            label: "Paga",
                                                            value:
                                                                PROF_TRANSFER_INVOICE_STATUS.PAID,
                                                        },
                                                        {
                                                            label: "Cancelada",
                                                            value:
                                                                PROF_TRANSFER_INVOICE_STATUS.CANCELED,
                                                        },
                                                    ]}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col sm="3">
                                            <FormGroup>
                                                <Label>Status Fiscal</Label>
                                                <Select
                                                    ref={inputFiscalStatus}
                                                    defaultValue={{
                                                        label: "Todos",
                                                        value: null,
                                                    }}
                                                    options={[
                                                        {
                                                            label: "Todos",
                                                            value: null,
                                                        },
                                                        {
                                                            label: "Aberta",
                                                            value:
                                                                PROF_TRANSFER_INVOICE_FISCAL_STATUS.OPEN,
                                                        },
                                                        {
                                                            label: "Emitida",
                                                            value:
                                                                PROF_TRANSFER_INVOICE_FISCAL_STATUS.ISSUED,
                                                        },
                                                        {
                                                            label:
                                                                "Transmitindo",
                                                            value:
                                                                PROF_TRANSFER_INVOICE_FISCAL_STATUS.TRANSMITTING,
                                                        },
                                                        {
                                                            label: "Falha",
                                                            value:
                                                                PROF_TRANSFER_INVOICE_FISCAL_STATUS.FAILED,
                                                        },
                                                        {
                                                            label: "Rejeitada",
                                                            value:
                                                                PROF_TRANSFER_INVOICE_FISCAL_STATUS.REJECTED,
                                                        },
                                                        {
                                                            label: "Cancelada",
                                                            value:
                                                                PROF_TRANSFER_INVOICE_FISCAL_STATUS.CANCELLED,
                                                        },
                                                        {
                                                            label:
                                                                "Substituída",
                                                            value:
                                                                PROF_TRANSFER_INVOICE_FISCAL_STATUS.REPLACED,
                                                        },
                                                    ]}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col sm="1" className="mt-1">
                                            <FormGroup>
                                                <div className="mt-4">
                                                    <Button
                                                        color="primary"
                                                        onClick={() =>
                                                            searchOptions()
                                                        }
                                                    >
                                                        Buscar
                                                    </Button>
                                                </div>
                                            </FormGroup>
                                        </Col>

                                        {canGen &&
                                        isCheck.length > 0 &&
                                        (invoiceStatus ==
                                            PROF_TRANSFER_INVOICE_STATUS.AVAILABLE_FOR_PAYMENT  || invoiceStatus ==
                                            PROF_TRANSFER_INVOICE_STATUS.OPEN)  ? (
                                            <Col sm="1" className="mt-1">
                                                <FormGroup>
                                                    <div className=" ">
                                                        <Link
                                                            className="btn btn-primary mt-4"
                                                            onClick={PayInvoice}
                                                        >
                                                            Pagar
                                                        </Link>
                                                    </div>
                                                </FormGroup>
                                            </Col>
                                        ) : (
                                            <></>
                                        )}

                                        <GenerateNfseButton
                                            isVisible={
                                                canGen &&
                                                isCheck.length > 0 &&
                                                invoiceStatus ==
                                                    PROF_TRANSFER_INVOICE_STATUS.PAID
                                            }
                                            onClick={confirmGenerateNfse}
                                        />
                                    </Row>

                                    <PaginationProvider
                                        pagination={paginationFactory(
                                            transferNotesList
                                        )}
                                    >
                                        {({
                                            paginationProps,
                                            paginationTableProps,
                                        }) => (
                                            <ToolkitProvider
                                                keyField="id"
                                                data={
                                                    transferNotesList.data || []
                                                }
                                                columns={TransferNotesColumns(
                                                    ModalInvoices
                                                )}
                                                bootstrap4
                                            >
                                                {toolkitProps => (
                                                    <React.Fragment>
                                                        <Row className="mb-2"></Row>
                                                        <Row>
                                                            <Col xl="12">
                                                                <div className="table-responsive">
                                                                    <BootstrapTable
                                                                        responsive
                                                                        remote
                                                                        bordered={
                                                                            false
                                                                        }
                                                                        striped={
                                                                            false
                                                                        }
                                                                        classes={
                                                                            "table table-centered table-nowrap"
                                                                        }
                                                                        headerWrapperClasses={
                                                                            "table-light"
                                                                        }
                                                                        {...toolkitProps.baseProps}
                                                                        selectRow={
                                                                            selectRow
                                                                        }
                                                                        onTableChange={
                                                                            onTableChange
                                                                        }
                                                                        noDataIndication={
                                                                            renderNoDataIndication
                                                                        }
                                                                        {...paginationTableProps}
                                                                    />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row className="align-items-md-center mt-30">
                                                            <Col className="pagination pagination-rounded justify-content-center mb-2 inner-custom-pagination">
                                                                <PaginationListStandalone
                                                                    {...paginationProps}
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </React.Fragment>
                                                )}
                                            </ToolkitProvider>
                                        )}
                                    </PaginationProvider>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <ModalTransferInvoices
                        show={modalTransferInvoiceData.show}
                        data={modalTransferInvoiceData.data}
                        onClose={status => {
                            setModalTransferInvoiceData({
                                show: false,
                                data: null,
                            })
                            if (status === "different") {
                                searchOptions()
                            }
                        }}
                    ></ModalTransferInvoices>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default ListTransferInvoices
