import React, { useState, useEffect, useMemo, useRef } from "react"
import {
    Container,
    Row,
    Col,
    CardBody,
    CardTitle,
    CardSubtitle,
    Button,
    Card,
    Input,
    Label,
    FormGroup,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownItem,
    DropdownMenu,
    UncontrolledTooltip,
    Badge,
    Spinner,
} from "reactstrap"
import { create as createExec } from "services/vitta-core/sales-order-item-exec"
import { remove as removeExec } from "services/vitta-core/sales-order-item-exec"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Link, useParams, useRouteMatch } from "react-router-dom"
import AsyncSelect from "react-select/async"
import { useSelector, useDispatch } from "react-redux"
import { useHistory, useLocation } from "react-router-dom"
import logovitta from "assets/images/logovitta2.jpg"
import { showSweetAlertDialog, showToast } from "store/layout/actions"
import HandleDataExecItem from "./HandleDataExecItem"
import HandlePricingItem from "./HandlePricingItem"
import HandlePricingAllItems from "./HandlePricingAllItems"
import { search as patientServiceSearch } from "services/vitta-core/patient"
import { search as itemServiceSearch } from "services/vitta-core/item"
import { search as professionalServiceSearch } from "services/vitta-core/professional"
import Select from "react-select"
import NumberFormat from "react-number-format"
import { Formik, Field, Form, FieldArray, FastField } from "formik"
import SALES_QUOTE_STATUS from "../../../utils/consts/sales-quotes-status"
import SALES_ORDER_STATUS from "../../../utils/consts/sales-orders-status"
import SALES_DOCS_TYPE from "../../../utils/consts/sales-docs-type"
import REQUEST_TYPES from "../../../utils/consts/request-prof-types"
import ModalExamRequests from "../ModalExamRequests/Modal"
import ModalAssessment from "./Odontologic/HandleAssessmentModal"
import { getById } from "services/vitta-core/orders-exams"
import HandleSelectItemsToPayModal from "./Odontologic/PayOdontologicItems/HandleSelectItemsToPayModal"
import {
    loadDataAddform as loadDataAddformQuote,
    loadDataEditform as loadDataEditformQuote,
    create as createQuote,
    update as updateQuote,
} from "services/vitta-core/sales-quote"
import {
    loadDataAddform as loadDataAddformOrder,
    loadDataEditform as loadDataEditformOrder,
    create as createOrder,
    update as updateOrder,
    execItem,
    cancelExecItem,
} from "services/vitta-core/sales-order"
import { search as itemPackageSearch } from "services/vitta-core/items-package"
import debounce from "debounce-promise"
import { getExamsOrdersBySalesOrder } from "services/vitta-core/orders-exams"
import ModalPayment from "pages/SalesDocs/Payment/index"
import PricingList from "./DiscountList/PricingList"
import { findPatient } from "services/vitta-core/patient"
import ModalBenefits from "./Benefits/ModalBenefits"
import SERVICES_TYPES from "utils/consts/services-types"
const today = new Date().toLocaleDateString("pt-BR")
import ModalAppointment from "pages/Appointments/List/ModalAppointment"
import ModalHistoricAppointments from "./HistoricAppointments/Index"
import { format, parseISO } from "date-fns"
import { ptBR } from "date-fns/locale"

const defaultItemValues = {
    id: null,
    item_id: null,
    quantity: 1,
    price_table: 0,
    price: 0,
    exec_professional_id: null,
    exec_in:
        today.substr(6, 4) +
        "-" +
        today.substr(3, 2) +
        "-" +
        today.substr(0, 2),
    voucher_partner_id: null,
    voucher_partner_price: 0,
    pricing: [],
}

var defaultValues = {
    patient_id: null,
    unit_id: null,
    request_prof_type: {
        label: "Não",
        value: REQUEST_TYPES.NO_REQUEST_MEDICAL,
    },
    obs: "",
    items: [defaultItemValues],
    generate_sales_order: false,
    onlyView: false,
}

const DateInput = ({
    field, // { name, value, onChange, onBlur }
    ...props
}) => <Input type="date" {...field} {...props} />

const TextAreaInput = ({
    field, // { name, value, onChange, onBlur }
    ...props
}) => <Input type="textarea" rows="1" {...field} {...props} />

const TextInput = ({
    field, // { name, value, onChange, onBlur }
    ...props
}) => <Input type="text" {...field} {...props} />

const NumberInput = ({
    field, // { name, value, onChange, onBlur }
    ...props
}) => <Input type="number" {...field} {...props} />

const TotalValueInput = ({
    field, // { name, value, onChange, onBlur }
    form: { setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    ...props
}) => {
    const [total, setTotal] = useState(0)

    useEffect(() => {
        let total_calc = 0

        for (let i = 0; i < props.formValues.items.length; i++) {
            total_calc +=
                props.formValues.items[i].quantity *
                props.formValues.items[i].price
        }

        setTotal(total_calc)
    }, [props.formValues])

    return (
        <NumberFormat
            {...field}
            {...props}
            className={props.className}
            value={total}
            onChange={e => {
                /*only to not dispatch this event*/
            }}
        />
    )
}

const CurrencyInput = ({
    field, // { name, value, onChange, onBlur }
    form: { setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    ...props
}) => (
    <NumberFormat
        {...field}
        onChange={e => {
            /*only to not dispatch this event*/
        }}
        onValueChange={option => {
            setFieldValue(field.name, option.floatValue)
        }}
        {...props}
    />
)

const AsyncSelectInput = ({
    field, // { name, value, onChange, onBlur }
    form,
    form: { setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    ...props
}) => (
    <AsyncSelect
        {...field}
        {...props}
        onChange={option => {
            if (field?.name == "patient_id") {
                props.VerifyPatientHasSubscription(option?.value)
            }
            setFieldValue(field.name, option)
        }}
        noOptionsMessage={() => "Sem Resultados. Digite para Buscar"}
        ref={props.RefPatient}
    />
)

const AsyncSelectInputItemService = ({
    field, // { name, value, onChange, onBlur }
    form,
    form: { setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    ...props
}) => {
    const [controlSend, setControlSend] = useState(false)

    if (controlSend) {
        props.handleInput(props?.valuesForm, setFieldValue)
        setControlSend(false)
    }

    // useEffect(() => {
    // if (controlSend) {
    //     props.handleInput(props?.valuesForm, setFieldValue)
    // }
    // if(props?.valuesForm?.patient_id?.vitta_card && props?.valuesForm?.patient_id?.vitta_card?.length>0)
    // {
    //     props.refreshOptionsVittaCard(
    //         props.valuesForm,
    //         form.setFieldValue
    //     )
    // }
    // }, [props.valuesForm])

    return (
        <AsyncSelect
            {...field}
            {...props}
            noOptionsMessage={() => "Sem Resultados. Digite para Buscar"}
            onChange={async option => {
                setControlSend(true)

                setFieldValue(field.name, option)

                if (option?.item?.price > 0) {
                    setFieldValue(props.price_input, option.item.price / 100)

                    setFieldValue(
                        "items[" + props.input_item_index + "].price_table",
                        option.item.price / 100
                    )
                } else {
                    setFieldValue(props.price_input, 0)
                    setFieldValue(
                        "items[" + props.input_item_index + "].price_table",
                        0
                    )
                }

                if (option?.item) {
                    let hasItem = props?.valuesForm?.items?.some(
                        e => e?.item_id?.value == option?.item?.id
                    )
                    if (hasItem) {
                        props.dispatch(
                            showSweetAlertDialog({
                                title: "Atenção!",
                                text:
                                    "Já existe este item no pedido, deseja adicionar outra linha com o mesmo Item?",
                                type: "warning",
                                onConfirmAction: () => {
                                    setFieldValue(
                                        "items[" +
                                            props.input_item_index +
                                            "].item_id",
                                        null
                                    )
                                    setFieldValue(
                                        "items[" +
                                            props.input_item_index +
                                            "].price",
                                        0
                                    )
                                    setFieldValue(
                                        "items[" +
                                            props.input_item_index +
                                            "].price_table",
                                        0
                                    )
                                    props.dispatch(
                                        showSweetAlertDialog({
                                            title: "Item removido!",
                                            type: "info",
                                            showConfirm: true,
                                        })
                                    )
                                },
                                showCancel: true,
                                showConfirm: true,
                                cancelBtnText: "Sim!",
                                confirmBtnText: "Não!",
                                closeOnClickOutside: false,
                            })
                        )
                    }

                    if (
                        option?.item?.group_id ==
                        process?.env?.REACT_APP_SURGERY_GROUP_ID
                    ) {
                        props.handleDisablePriceField(
                            option,
                            field.name,
                            setFieldValue
                        )
                    }

                    setFieldValue(
                        "items[" + props.input_item_index + "].pricing",
                        []
                    )
                }

                let unit_ref_value =
                    props.RefUnit?.current?.select?.state?.selectValue[0]?.value

                if (
                    option?.item?.is_package &&
                    option?.item?.is_package === 1 &&
                    unit_ref_value
                ) {
                    props.arrayHelpers.remove(props.input_item_index)
                    try {
                        let response = await itemPackageSearch({
                            id: option?.item?.id,
                            unit_id: unit_ref_value,
                        })
                        for (let i = 0; i < response.data.length; i++) {
                            let row = response.data[i]
                            let flag = props?.valuesForm?.items?.find(
                                e => e?.item_id?.value == row?.item?.id
                            )

                            if (!flag) {
                                let data = {
                                    id: null,
                                    item_id: {
                                        label: row?.item?.name,
                                        value: row?.item?.id,
                                        item: {
                                            ...row.item,
                                            price:
                                                row?.item?.price_table_item
                                                    ?.price,
                                            price_table_id:
                                                row?.item?.price_table_item
                                                    ?.price_table_id,
                                        },
                                    },
                                    quantity: 1,
                                    price_table: row.item.price_table_item
                                        ? row?.item?.price_table_item?.price /
                                          100
                                        : 0,
                                    price: row.item.price_table_item
                                        ? row?.item?.price_table_item?.price /
                                          100
                                        : 0,
                                    exec_professional_id: null,
                                    exec_in:
                                        today.substr(6, 4) +
                                        "-" +
                                        today.substr(3, 2) +
                                        "-" +
                                        today.substr(0, 2),
                                    voucher_partner_id: null,
                                    voucher_partner_price: 0,
                                    pricing: [],
                                }

                                props.arrayHelpers.push(data)
                            }
                        }
                        return
                    } catch (e) {
                        console.log(e)
                    }
                }
            }}
        />
    )
}

const ReactSelectInput = ({
    field, // { name, value, onChange, onBlur }
    form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    form: { setFieldValue },
    ...props
}) => {
    return (
        <Select
            {...field}
            {...props}
            onChange={option => {
                form.setFieldValue(field.name, option)
                if (field?.name == "request_prof_type") {
                    props?.state(option?.value)
                }
                if (field?.name == "unit_id" && option) {
                    props.refreshOptions(
                        option.value,
                        props.valuesForm,
                        form.setFieldValue,
                        option
                    )

                    props.alertChange()
                }
            }}
            ref={props.RefUnit}
        />
    )
}

const RegistrationSalesOrders = () => {
    const inputRefUnit = useRef()
    const inputRefPatient = useRef()
    const { id } = useParams()
    const [pageData, setPageData] = useState({
        formOrderValues: defaultValues,
    })
    const [modalAppointmentData, setModalAppointmentData] = useState({
        show: false,
        data: null,
    })
    const [modalPaymentData, setModalPaymentData] = useState({
        show: false,
        data: null,
    })
    const [modalDataExecItem, setModalDataExecItem] = useState({
        show: false,
        data: null,
    })
    const [modalPricingList, setModalPricingList] = useState({
        show: false,
        data: null,
        user: null,
        removePricing: null,
    })
    const [modalPricingItem, setModalPricingItem] = useState({
        show: false,
        data: null,
        applyPricingForm: null,
        applyRulePricing: null,
    })
    const [modalPricingAllItems, setModalPricingAllItems] = useState({
        show: false,
        data: null,
        refreshItemsForm: null,
    })
    const [modalBenefits, setModalBenefits] = useState({
        show: false,
        data: null,
    })
    const [modalAssessment, setModalAssessment] = useState({
        show: false,
        data: null,
        details: false,
        doc_service_type: null,
    })
    const [controlRequestProf, setControlRequestProf] = useState(0)

    const [
        controlRequestProfDisabled,
        setControlRequestProfDisabled,
    ] = useState(false)

    const [controlModalExamRequest, setControlModalExamRequest] = useState({
        show: false,
        data: null,
    })
    const [patientHasSubscription, setPatientHasSubscription] = useState({
        has: false,
        data: null,
        error: false,
    })
    const [
        showLoadingVerifySubscription,
        setLoadingVerifySubscription,
    ] = useState(false)
    const [modalHistoricAppointments, setModalHistoricAppointments] = useState({
        show: false,
    })
    const routeMatch = useRouteMatch()
    let pageSalesDocType = null

    if (routeMatch.path.includes("orcamentos/"))
        pageSalesDocType = SALES_DOCS_TYPE.SALES_QUOTE
    else if (routeMatch.path.includes("pedidos/"))
        pageSalesDocType = SALES_DOCS_TYPE.SALES_ORDER
    else if (routeMatch.path.includes("notasfiscais/"))
        pageSalesDocType = SALES_DOCS_TYPE.SALES_INVOICE

    const [idStatus, setIdStatus] = useState(null)

    const dispatch = useDispatch()

    const history = useHistory()
    const stateLocation = useLocation()

    const Scope = useSelector(state => state.scope)
    const User = useSelector(state => state.auth.access.user)

    useEffect(() => {
        if (id) loadDataEditPage()
        else loadDataAddPage()
    }, [idStatus])

    const pay_terms = pageData.pay_terms
    const pay_termsOptions = useMemo(() => {
        if (!pay_terms) return []

        let options_list = []
        pay_terms.forEach(element => {
            options_list.push({ label: element.name, value: element.id })
        })
        return options_list
    }, [pay_terms])

    const pay_forms = pageData.pay_forms
    const pay_formsOptions = useMemo(() => {
        if (!pay_forms) return []

        let options_list = []
        pay_forms.forEach(element => {
            options_list.push({ label: element.name, value: element.id })
        })
        return options_list
    }, [pay_forms])

    const unitsOptions = useMemo(() => {
        if (!User?.units) return []

        let options_list = []
        User.units.forEach(element => {
            options_list.push({
                label: element.name?.substr(0, 40),
                value: element.id,
            })
        })
        return options_list
    }, [User.units])

    const prof_type_options = useMemo(() => {
        if (!User?.units) return []

        let options_list = [
            {
                value: REQUEST_TYPES.NO_REQUEST_MEDICAL,
                label: "Não",
            },
            {
                value: REQUEST_TYPES.REQUEST_INTERNAL,
                label: "Interna",
            },
            { value: REQUEST_TYPES.REQUEST_EXTERNAL, label: "Externa" },
        ]

        return options_list
    }, [User.units])

    const loadPatientInputOptions = async inputValue => {
        return new Promise(async (resolve, reject) => {
            try {
                if (!inputValue) return resolve([])

                let response = await patientServiceSearch(
                    {
                        term: inputValue,
                        status: 1,
                        expand_vitta_cards: 1,
                        fields: "id,name,cpf",
                    },
                    1,
                    10
                )

                let patients_values = []
                response.data.forEach(element => {
                    patients_values.push({
                        label:
                            `${element.id} - ` +
                            element.name +
                            (element.cpf ? ` <${element.cpf}>` : ""),
                        value: element.id,
                        // vitta_card: element.vitta_card,
                        // vitta_card_dependent: element.vitta_card_dependent,
                        employee_relation: element.employee_relation,
                    })
                })

                return resolve(patients_values)
            } catch (e) {
                console.error(e)
                return reject([])
            }
        })
    }

    const loadItemInputOptions = async inputValue => {
        return new Promise(async (resolve, reject) => {
            try {
                const statusActive = 1
                const unit_id_control =
                    inputRefUnit?.current?.select?.state?.selectValue[0]?.value

                if (!inputValue) return resolve([])
                if (!unit_id_control) return resolve([])

                let response = await itemServiceSearch(
                    {
                        term: inputValue,
                        unit_id: unit_id_control,
                        status: statusActive,
                        origin:
                            pageSalesDocType == SALES_DOCS_TYPE.SALES_ORDER
                                ? "sales_orders"
                                : "sales_quotes",
                        needed_price: true,
                    },
                    1,
                    20
                )

                let items_values = []
                response.data.forEach(element => {
                    element.price = element.price_table_item?.price
                        ? element.price_table_item?.price
                        : 0
                    element.price_table_id = element.price_table_item
                        ?.price_table_id
                        ? element.price_table_item?.price_table_id
                        : null

                    items_values.push({
                        label: element.name,
                        value: element.id,
                        item: element,
                    })
                })

                return resolve(items_values)
            } catch (e) {
                console.error(e)
                return reject([])
            }
        })
    }

    const loadProfessionalsInputOptions = async inputValue => {
        return new Promise(async (resolve, reject) => {
            try {
                if (!inputValue) return resolve([])
                const statusActive = 1
                let response = await professionalServiceSearch(
                    { term: inputValue, status: statusActive },
                    1,
                    20
                )

                let items_values = []

                response.data.forEach(element => {
                    items_values.push({
                        label: element.name,
                        value: element.id,
                    })
                })

                return resolve(items_values)
            } catch (e) {
                console.error(e)
                return reject([])
            }
        })
    }

    const debounceOptionsPatient = debounce(loadPatientInputOptions, 600)
    const debounceOptionsItem = debounce(loadItemInputOptions, 600)
    const debounceOptionsProfessional = debounce(
        loadProfessionalsInputOptions,
        600
    )
    const handlePrint = () => {
        window.print()
    }
    const execItemWithPermission = data => {
        return setModalAssessment({
            show: true,
            data: data,
            details: true,
        })
    }
    const confirmCancelExecItem = (data, index) => {
        dispatch(
            showSweetAlertDialog({
                title: "Atenção ",
                text:
                    'Tem certeza que deseja "CANCELAR A EXECUÇÃO" deste item?',
                type: "warning",
                onConfirmAction: () => {
                    handleCancelExecItem(data, index)
                },
                showCancel: true,
                showConfirm: true,
                cancelBtnText: "Não",
                confirmBtnText: "Sim",
            })
        )
    }
    const handleCancelExecItem = async (data, index) => {
        let dataSubmit = {
            itemId: data?.id,
        }
        try {
            await cancelExecItem(id, dataSubmit)
            handleStylesItemCancelExec(index)
            loadDataEditPage()
            dispatch(
                showToast({
                    text: "Ação concluída!",
                    type: "success",
                })
            )
        } catch (e) {
            console.log(e)
            if (
                e.response.data.error_code ===
                "professional_transfer_invoice_existent"
            ) {
                dispatch(
                    showSweetAlertDialog({
                        title: "Ops...",
                        text:
                            "Não é possível cancelar a execução deste item pois o mesmo possui vínculo com uma nota de repasse.",
                        type: "error",
                        showConfirm: true,
                    })
                )
            }
        }
    }
    const confirmExecItem = (data, index) => {
        dispatch(
            showSweetAlertDialog({
                title: "Atenção ",
                text: 'Tem certeza que deseja "EXECUTAR" este item?',
                type: "warning",
                onConfirmAction: () => {
                    handleExecItem(data, index)
                },
                showCancel: true,
                showConfirm: true,
                cancelBtnText: "Não",
                confirmBtnText: "Sim",
            })
        )
    }
    const handleExecItemOdont = async data => {
        try {
            const sendData = {
                sales_order_item_id: data.id,
                service_stage_id: null,
                service_id: data?.item_id?.value,
                exec_in: null,
                exec_professional_id: null,
            }
            if (pageData?.doc?.service_type != SERVICES_TYPES.DENTAL) {
                sendData.exec_in = data.exec_in
                sendData.exec_professional_id = data.exec_professional_id?.value
            }
            await createExec(sendData)

            dispatch(
                showToast({
                    title: "Sucesso!",
                    text: "Execução realizada com sucesso!",
                    type: "success",
                })
            )
            loadDataEditPage()
        } catch (e) {
            console.log(e)
            if (e?.response?.data) {
                return dispatch(
                    showSweetAlertDialog({
                        title: "Ops...",
                        text: e?.response?.data.message,
                        type: "error",
                        showConfirm: true,
                    })
                )
            }
            dispatch(
                showSweetAlertDialog({
                    title: "Ops...",
                    text:
                        "Não foi possível realizar a execução, tente novamente mais tarde...",
                    type: "error",
                    showConfirm: true,
                })
            )
        }
    }
    const handleExecItem = async (data, index) => {
        let dataSubmit = {
            itemId: data?.id,
        }
        try {
            await execItem(id, dataSubmit)
            handleStylesItemExec(index)
            loadDataEditPage()
            dispatch(
                showToast({
                    text: "Ação concluída!",
                    type: "success",
                })
            )
        } catch (e) {
            if (e.response.data.error_code === "register_status_open") {
                dispatch(
                    showSweetAlertDialog({
                        title: "Ops... ",
                        text:
                            "Para reabrir o serviço é necessário cancelar a nota de repasse.",
                        type: "error",
                        showConfirm: true,
                    })
                )
            }
            console.log(e)
        }
    }
    const handleStylesItemExec = index => {
        let id = "execin" + index
        let element = document.getElementById(id)
        element.style.borderColor = "#0195d5"
        element.style.cursor = "pointer"
    }
    const handleStylesItemCancelExec = index => {
        let id = "execin" + index
        let element = document.getElementById(id)
        element.style.borderColor = "#f1b54f"
    }

    const submitAction = async (id, data) => {
        try {
            if (id) {
                if (pageSalesDocType == SALES_DOCS_TYPE.SALES_QUOTE) {
                    await updateQuote(id, data)
                    setIdStatus(new Date().getTime()) //Renderizar o form
                    history.replace(`/orcamentos/${id}`, {
                        previousPath: stateLocation?.state?.previousPath,
                    })
                } else if (pageSalesDocType == SALES_DOCS_TYPE.SALES_ORDER) {
                    await updateOrder(id, data)
                    setIdStatus(new Date().getTime()) //Renderizar o form
                    history.replace(`/pedidos/${id}`, {
                        previousPath: stateLocation?.state?.previousPath,
                    })
                }
            } else {
                if (pageSalesDocType == SALES_DOCS_TYPE.SALES_QUOTE) {
                    let quote_created = await createQuote(data)
                    history.replace(`/orcamentos/${quote_created.id}`, {
                        previousPath: stateLocation?.state?.previousPath,
                    })
                } else if (pageSalesDocType == SALES_DOCS_TYPE.SALES_ORDER) {
                    let order_created = await createOrder(data)
                    history.replace(`/pedidos/${order_created.id}`, {
                        previousPath: stateLocation?.state?.previousPath,
                    })
                }
            }

            dispatch(
                showToast({
                    title: "Sucesso",
                    text: "Registro salvo com Sucesso!",
                    type: "success",
                })
            )
        } catch (e) {
            console.log(e)

            let title = "Ops... "
            let textError =
                "Houve um erro ao processar sua solicitação, certifique-se de preencher os campos corretamente ou tente novamente mais tarde..."

            if (e?.response?.status == 403) {
                textError =
                    "Você não possui permissões para realizar essa operação. Solicite ao seu Gestor."
            }

            if (e.response.data.error_code == "max_discount_group_item") {
                dispatch(
                    showSweetAlertDialog({
                        title: "Atenção!",
                        text: `Não é possível aplicar um desconto maior que 5% ao item (${e?.response?.data?.error_data?.name}).`,
                        type: "warning",
                        showConfirm: true,
                    })
                )
                return
            }
            if (e.response.data.error_code == "patient_is_not_collaborator") {
                dispatch(
                    showSweetAlertDialog({
                        title: "Desconto não autorizado!",
                        text: `Esse paciente não é um colaborador da empresa.`,
                        type: "warning",
                        showConfirm: true,
                    })
                )
                return
            }
            if (
                e.response.data.error_code ==
                "unauthorized_user_for_discount_item"
            ) {
                dispatch(
                    showSweetAlertDialog({
                        title: "Atenção!",
                        text: `Usuário não autorizado para desconto ao item (${e?.response?.data?.error_data?.name}).`,
                        type: "warning",
                        showConfirm: true,
                    })
                )
                return
            }

            if (
                e?.response?.data?.error_code ==
                "user_discount_policy_violation"
            ) {
                return dispatch(
                    showSweetAlertDialog({
                        title: "Atenção! ",
                        text:
                            "Os descontos aplicados violam a política de descontos da empresa. Remova os descontos ou solicite uma aprovação. Deseja solicitar uma aprovação da Diretoria para este pedido? Obs: O pedido não poderá ser pago até ter sido aprovado.",
                        type: "warning",
                        onConfirmAction: async () => {
                            data.canGenSalesOrderApproval = true
                            await submitAction(id, data)
                        },
                        showCancel: true,
                        showConfirm: true,
                        cancelBtnText: "Não",
                        confirmBtnText: "Sim",
                    })
                )
            }

            if (
                e?.response?.data?.error_code ==
                "trial_budget_not_available_for_editing"
            ) {
                dispatch(
                    showSweetAlertDialog({
                        title: "Atenção!",
                        text: `Não é possível atualizar este orçamento. Avaliação odontológica em negociação ou já negociada!`,
                        type: "warning",
                        showConfirm: true,
                    })
                )
                return
            }
            if (e?.response?.data?.error_code == "invalid_group_item") {
                dispatch(
                    showSweetAlertDialog({
                        title: "Atenção!",
                        text: `Para serviços que possuem agenda, é necessário criar o pedido de venda através de um agendamento.`,
                        type: "info",
                        showConfirm: true,
                    })
                )
                return
            }
            if (e?.response?.data?.error_code == "need_mother_name") {
                dispatch(
                    showSweetAlertDialog({
                        title: "Atenção!",
                        text: `Para exames laboratoriais, é necessário informar o nome da mãe do paciente em seu cadastro.`,
                        type: "info",
                        showConfirm: true,
                    })
                )
                return
            }
            if (e?.response?.data) {
                switch (e.response.data.error_code) {
                    case "patient_register_incomplete":
                        textError =
                            "O Paciente precisa estar com seu cadastro completo. Necessário dados pessoais como nome, cpf, sexo, data de nascimento, dados de contato e endereço."
                        break
                    case "patient_not_active":
                        textError =
                            "O Paciente precisa estar com seu cadastro ativo."
                        break
                    case "patient_not_found":
                        textError = "O Paciente não foi encontrado."
                        break
                    case "item_in_voucher_group_need_partner":
                        textError =
                            "Informe o Parceiro e o valor cobrado por ele nos items que geram Vouchers, como os Exames Externos."
                        break
                    case "date_exec_in_items_invalid":
                        textError =
                            "Data de execução inválida, digite uma data superior à correspondente."
                        break
                    case "professional_not_indicated":
                        title =
                            "Para exames/procedimentos é necessário preencher o campo solicitação médica!"
                        textError = ""
                        break
                    case "not_referring_physician":
                        title =
                            'É necessário preencher o campo "Médico Solicitante" ao selecionar uma solicitação médica!'
                        textError = ""
                        break
                    case "sales_order_active_payments":
                        textError =
                            "Existem pagamentos ativos para este pedido. Cancele primeiro os pagamentos."
                        break
                    case "discount_application_not_allowed":
                        textError =
                            "Sem permissão para aplicar descontos manuais. Solicite a um usuário autorizado para salvar o pedido com descontos aplicados."
                        break
                    case "unauthorized_user_for_action":
                        textError = `Usuário não autorizado para venda de cirurgia.`
                        break
                    case "already_vitta_card_discount":
                        textError = `Não é possível aplicar um desconto manual ao item, pois será aplicado desconto automático do cartão vittá + .`
                        break
                    case "total_discount_limit_exceeded":
                        textError = `O valor máximo para um desconto manual é de 100%.`
                        break
                    case "item_not_released_for_discount":
                        textError =
                            "Não é possível aplicar desconto manual ao item " +
                            e?.response?.data?.error_data?.name
                        break
                    case "cant_change_ind_professional":
                        textError =
                            "Para orçamentos e pedidos gerados por solicitação médica, não é possível alterar o médico solicitante."
                        break
                    case "item_package":
                        textError = "Remova os serviços de pacote"
                        break
                }
            }

            dispatch(
                showSweetAlertDialog({
                    title: title,
                    text: textError,
                    type: "error",
                    showConfirm: true,
                })
            )
        }
    }
    const splitDateTime = (start_exec, end_exec) => {
        if (!start_exec || !end_exec) return

        // Criar objetos Date para start_exec e end_exec
        const startDate = new Date(start_exec)
        const endDate = new Date(end_exec)

        // Formatar a data para obter o dia da semana e a descrição
        const dayOfWeek = format(startDate, "EEEE", { locale: ptBR })
        const description = `${format(startDate, "dd")} de ${format(
            startDate,
            "MMMM",
            { locale: ptBR }
        )}, ${dayOfWeek}`

        // Obter os horários formatados (hora:minuto)
        const startTime = format(startDate, "HH:mm")
        const endTime = format(endDate, "HH:mm")

        // Criar os objetos resultantes
        const dateObj = {
            date: format(startDate, "yyyy-MM-dd"),
            description: description,
        }

        const timeObj = {
            start: startTime,
            end: endTime,
        }

        return { dateObj, timeObj }
    }

    const onSubmit = async data_form => {
        //data_form é passado como referência
        let data = JSON.parse(JSON.stringify(data_form))

        for (let i = 0; i < data.items.length; i++) {
            if (!data.items[i].item_id) continue

            data.items[i].item_id = data.items[i].item_id.value
            data.items[i].price = parseInt(
                data?.items[i]?.price?.toFixed(2)?.replace(/[^0-9]/g, "")
            )
            data.items[i].price_table_id =
                data_form?.items[i]?.item_id?.item?.price_table_id
            data.items[i].exec_professional_id = data.items[i]
                .exec_professional_id
                ? data.items[i].exec_professional_id.value
                : null

            data.items[i].exec_in = data.items[i].exec_in

            data.items[i].voucher_partner_id = data?.items[i]
                ?.voucher_partner_id
                ? data?.items[i]?.voucher_partner_id?.value
                : null

            data.items[i].voucher_partner_price = parseInt(
                data?.items[i]?.voucher_partner_price
                    ?.toFixed(2)
                    ?.replace(/[^0-9]/g, "")
            )
            // if(data?.items[i]?.discounts?.length>0)
            // {
            //     for( let j=0; data?.items[i]?.discounts.length; j++)
            //     {
            //         let useDiscount = data?.items[i]?.discounts[j];

            //     }
            // }
        }

        data.patient_id = data.patient_id ? data.patient_id.value : null
        data.ind_professional_id =
            data?.ind_professional_id &&
            controlRequestProf === REQUEST_TYPES.REQUEST_INTERNAL
                ? data.ind_professional_id.value
                : null
        data.request_prof_name =
            data.ind_professional_text &&
            data.ind_professional_text != "" &&
            controlRequestProf === REQUEST_TYPES.REQUEST_EXTERNAL
                ? data.ind_professional_text
                : null
        data.request_prof_type = controlRequestProf
        data.pay_terms_id = data.pay_terms_id ? data.pay_terms_id.value : null
        data.pay_form_id = data.pay_form_id ? data.pay_form_id.value : null
        data.unit_id = data.unit_id.value
        data.subscription = patientHasSubscription?.has
            ? patientHasSubscription.data?.expand_vitta_cards
            : null
        await submitAction(id, data)
    }

    const loadDataAddPage = async () => {
        try {
            let data = null

            if (pageSalesDocType == SALES_DOCS_TYPE.SALES_QUOTE)
                data = await loadDataAddformQuote()
            else if (pageSalesDocType == SALES_DOCS_TYPE.SALES_ORDER)
                data = await loadDataAddformOrder()

            let form_data = { ...defaultValues }

            if (Scope.unit) {
                form_data.unit_id = {
                    label: Scope.unit.name?.substr(0, 40),
                    value: Scope.unit.id,
                }
            }

            setPageData({
                pay_terms: data.pay_terms,
                pay_forms: data.pay_forms,
                formOrderValues: form_data,
            })
        } catch (e) {
            console.log(e)
        }
    }

    const loadDataEditPage = async () => {
        try {
            let data = null

            if (pageSalesDocType == SALES_DOCS_TYPE.SALES_QUOTE)
                data = await loadDataEditformQuote(id)
            else if (pageSalesDocType == SALES_DOCS_TYPE.SALES_ORDER)
                data = await loadDataEditformOrder(id)

            if (data?.doc?.request_prof_type != 0) {
                setControlRequestProfDisabled(false)
            } else {
                setControlRequestProfDisabled(true)
            }

            if (data?.doc?.request_prof_type) {
                setControlRequestProf(data?.doc?.request_prof_type)
            }

            data.doc.electronic_vittapag_payment_status = data?.doc?.unit?.electronic_vittapag_payment_status;

            let itemsForm = []
            const tamVetDocItems = data?.doc?.items?.length
            for (let i = 0; i < tamVetDocItems; i++) {
                let useItem = data.doc.items[i]

                let itemsDiscount = []
                if (useItem?.discounts && useItem?.discounts?.length > 0) {
                    for (let i = 0; i < useItem?.discounts.length; i++) {
                        let discount = useItem?.discounts[i]
                        let itemRule = null
                        if (discount?.origin == "vitta_card") {
                            itemRule = {
                                type: discount?.rule_type,
                                code: discount?.rule_type_code,
                                discount_fix: discount?.rule_discount_fix
                                    ? discount?.rule_discount_fix
                                    : 0,
                                discount_percent: discount?.rule_discount_percent
                                    ? discount?.rule_discount_percent
                                    : 0,
                            }
                        }
                        let item = {
                            id: discount?.id,
                            origin: discount?.origin,
                            value: discount?.value,
                            rule: itemRule,
                        }

                        itemsDiscount.push(item)
                    }
                }
                let exec_professional_id = null
                let exec_in =
                    data?.doc?.service_type == SERVICES_TYPES.DENTAL
                        ? today.substr(6, 4) +
                          "-" +
                          today.substr(3, 2) +
                          "-" +
                          today.substr(0, 2)
                        : useItem.exec_in
                        ? useItem.exec_in.substr(0, 10)
                        : null
                //Pedido ainda não pago
                if (useItem.exec_professional) {
                    exec_professional_id = {
                        label: useItem.exec_professional.name,
                        value: useItem.exec_professional.id,
                    }
                }

                //Pedido já tem OS, comissão...
                if (
                    pageSalesDocType == SALES_DOCS_TYPE.SALES_ORDER &&
                    [
                        SALES_ORDER_STATUS.PAID,
                        SALES_ORDER_STATUS.INVOICE_ISSUED,
                    ].includes(data.doc.status) &&
                    useItem.os_item &&
                    useItem.os_item.exec_professional
                ) {
                    exec_professional_id = {
                        label: useItem.os_item.exec_professional.name,
                        value: useItem.os_item.exec_professional.id,
                    }
                    exec_in = useItem.os_item.exec_in
                        ? useItem.os_item.exec_in.substr(0, 10)
                        : exec_in
                }
                itemsForm.push({
                    id: useItem.id,
                    item_id: {
                        label: useItem.item.name,
                        value: useItem.item_id,
                        item: {
                            price_table_id: useItem.price_table_id,
                            group_id: useItem?.item?.group_id,
                        },
                        showInputPriceDisabled:
                            (useItem?.item?.group_id ==
                                process.env.REACT_APP_SURGERY_GROUP_ID &&
                                hasPermissionOfSurgeryGroupItem) ||
                            data?.doc?.service_type == SERVICES_TYPES.DENTAL
                                ? true
                                : false,
                    },
                    quantity: useItem.quantity,
                    price_table:
                        useItem.price_table > 0 ? useItem.price_table / 100 : 0,
                    price: useItem.price > 0 ? useItem.price / 100 : 0,
                    exec_professional_id: exec_professional_id,
                    exec_in: exec_in,
                    voucher_partner_id: useItem.voucher_partner
                        ? {
                              label: useItem.voucher_partner.name,
                              value: useItem.voucher_partner.id,
                          }
                        : null,
                    voucher_partner_price:
                        useItem.voucher_partner_price > 0
                            ? useItem.voucher_partner_price / 100
                            : 0,
                    mark_exec_at: useItem?.mark_exec_at,
                    user_mark_exec: useItem?.user_mark_exec,
                    // discounts: itemsDiscount,
                    pricing: useItem?.pricing,
                    tooth_number: useItem?.tooth_number,
                    lab_cost_id: useItem?.lab_cost_id,
                    lab_cost: useItem?.lab_cost,
                    price_lab: useItem?.price_lab,
                    service_stage: useItem?.item?.service_stages,
                    sales_order_exec: useItem?.sales_order_exec,
                    appointment_id: useItem?.appointment_id,
                    appointment: useItem?.appointment,
                })
            }

            let dataToRefreshState = {
                doc: data.doc,
                formOrderValues: {
                    id: data.doc.id,
                    unit_id: {
                        label: data.doc.unit.name,
                        value: data.doc.unit.id,
                    },
                    created_at:
                        data?.doc?.created_at?.substr(0, 4) +
                        "-" +
                        data?.doc?.created_at?.substr(5, 2) +
                        "-" +
                        data?.doc?.created_at?.substr(8, 2),
                    patient_id: {
                        label:
                            `${data.doc.patient.id} - ` +
                            data.doc.patient.name +
                            (data.doc.patient.cpf
                                ? ` <${data.doc.patient.cpf}>`
                                : ""),
                        value: data.doc.patient.id,
                        vitta_card: data.doc.patient.vitta_card,
                        vitta_card_dependent:
                            data.doc.patient.vitta_card_dependent,
                        employee_relation: data.doc.patient.employee_relation,
                    },
                    ind_professional_id: data.doc.ind_professional
                        ? {
                              label: data.doc.ind_professional.name,
                              value: data.doc.ind_professional.id,
                          }
                        : null,
                    ind_professional_text:
                        data?.doc?.request_prof_name &&
                        data?.doc?.request_prof_name != ""
                            ? data?.doc?.request_prof_name
                            : null,
                    request_prof_type:
                        data?.doc?.request_prof_type == 1
                            ? { label: "Interna", value: 1 }
                            : data?.doc?.request_prof_type == 2
                            ? { label: "Externa", value: 2 }
                            : { value: 0, label: "Não" },
                    items: itemsForm,
                    obs: data.doc.obs,
                    status: data.doc.status,
                    os: data.doc.os,
                    pay_form_id: data.doc.pay_form
                        ? {
                              label: data.doc.pay_form.name,
                              value: data.doc.pay_form.id,
                          }
                        : null,
                    pay_terms_id: data.doc.pay_terms
                        ? {
                              label: data.doc.pay_terms.name,
                              value: data.doc.pay_terms.id,
                          }
                        : null,
                    onlyView:
                        (pageSalesDocType == SALES_DOCS_TYPE.SALES_QUOTE &&
                            data.doc.status != SALES_QUOTE_STATUS.AVAILABLE) ||
                        (pageSalesDocType == SALES_DOCS_TYPE.SALES_ORDER &&
                            data.doc.status !=
                                SALES_ORDER_STATUS.AWAITING_COMPANY_APPROVAL &&
                            data.doc.status !=
                                SALES_ORDER_STATUS.AWAITING_PAYMENT &&
                            data.doc.status !=
                                SALES_ORDER_STATUS.PARTIALLY_PAID &&
                            data.doc.status !=
                                SALES_ORDER_STATUS.COMPANY_NOT_APPROVED),
                },
                pay_terms: data.pay_terms,
                pay_forms: data.pay_forms,
                vitta_card_discounts: data.vitta_card_discounts,
                medical_exams_id: data.doc.medical_exams_id
                    ? data.doc.medical_exams_id
                    : null,
                service_type: data?.doc?.service_type
                    ? data?.doc?.service_type
                    : SERVICES_TYPES.CLINICAL,
                assessment_id: data?.doc?.assessment_id,
            }

            setPageData(dataToRefreshState)

            VerifyPatientHasSubscription(data?.doc?.patient?.id)

            return dataToRefreshState
        } catch (e) {
            dispatch(
                showToast({
                    title: "Ops..",
                    text: "Registro não encontrado.",
                    type: "error",
                })
            )

            console.log(e)

            if (pageSalesDocType == SALES_DOCS_TYPE.SALES_QUOTE)
                history.push("/orcamentos")
            else if (pageSalesDocType == SALES_DOCS_TYPE.SALES_ORDER)
                history.push("/pedidos")
            else history.push("/")
        }
    }

    const renderBadgeSalesQuoteStatus = status => {
        let text = ""
        let badgeClass = "badge-soft-success"
        switch (status) {
            case SALES_QUOTE_STATUS.AVAILABLE:
                text = "Disponível"
                badgeClass = "badge-soft-success"
                break
            case SALES_QUOTE_STATUS.EXPIRED:
                text = "Expirado"
                badgeClass = "badge-soft-danger"
                break
            case SALES_QUOTE_STATUS.CONVERTED_INTO_ORDER:
                text = "Convertido em Pedido"
                badgeClass = "badge-soft-primary"
                break
            case SALES_QUOTE_STATUS.IN_NEGOTIATION:
                text = "Em Negociação"
                badgeClass = "badge-soft-info"
                break
        }

        return (
            <Badge className={"font-size-12 " + badgeClass} pill>
                {text}
            </Badge>
        )
    }

    const renderBadgeSalesOrderStatus = status => {
        let text = ""
        let badgeClass = "badge-soft-success"
        switch (status) {
            case SALES_ORDER_STATUS.AWAITING_COMPANY_APPROVAL:
                text = "Aguardando Aprovação"
                badgeClass = "badge-soft-warning"
                break
            case SALES_ORDER_STATUS.COMPANY_NOT_APPROVED:
                text = "Não Aprovado"
                badgeClass = "badge-soft-danger"
                break
            case SALES_ORDER_STATUS.AWAITING_PAYMENT:
                text = "Aguardando Pagamento"
                badgeClass = "badge-soft-success"
                break
            case SALES_ORDER_STATUS.PAID:
                text = "Pago"
                badgeClass = "badge-soft-success"
                break
            case SALES_ORDER_STATUS.INVOICE_ISSUED:
                text = "Notas Fiscais Emitidas"
                badgeClass = "badge-soft-primary"
                break
            case SALES_ORDER_STATUS.CANCELED:
                text = "Cancelado"
                badgeClass = "badge-soft-danger"
                break
            case SALES_ORDER_STATUS.PARTIALLY_PAID:
                text = "Parcialmente Pago"
                badgeClass = "badge-soft-info"
                break
        }

        return (
            <Badge className={"font-size-12 " + badgeClass} pill>
                {text}
            </Badge>
        )
    }

    const refreshOptions = async (unit_id, values, setFieldValue) => {
        try {
            let itemsId = []
            let result = null
            const tamVI = values?.items?.length
            for (let i = 0; i < tamVI; i++) {
                const item = values.items[i]
                if (
                    item?.item_id?.value &&
                    !itemsId.includes(item?.item_id?.value)
                ) {
                    itemsId.push(item?.item_id?.value)
                }
            }

            if (unit_id && itemsId.length > 0) {
                result = await itemServiceSearch({
                    unit_id: unit_id,
                    id: itemsId,
                })
            }

            if (result?.data?.length > 0) {
                const tamVdb = result?.data?.length
                for (let i = 0; i < tamVI; i++) {
                    const item = values.items[i]
                    for (let j = 0; j < tamVdb; j++) {
                        const itemDb = result.data[j]
                        if (
                            item.item_id.value === itemDb.id &&
                            itemDb?.price_table_item?.price >= 0
                        ) {
                            setFieldValue(
                                `items[${i}].price_table`,
                                itemDb.price_table_item.price / 100
                            )
                            setFieldValue(
                                `items[${i}].price`,
                                itemDb.price_table_item.price / 100
                            )
                            setFieldValue(
                                `items[${i}].item_id.item.price_table_id`,
                                itemDb.price_table_item.price_table_id
                            )
                            setFieldValue(
                                `items[${i}].item_id.item.price_table_item.price`,
                                itemDb.price_table_item.price
                            )

                            let dataItem = { item: { ...itemDb } }

                            refreshPricing(
                                values?.patient_id,
                                setFieldValue,
                                dataItem,
                                "",
                                j,
                                item
                            )

                            break
                        }
                    }
                }
            }
        } catch (e) {
            console.log(e)
        }
    }

    const refreshPricing = async (
        patientData,
        setFieldValue,
        optionItem,
        name,
        index,
        item
    ) => {
        if (item?.pricing?.length > 0) {
            setFieldValue(`items[${index}].pricing`, [])
            item.pricing = []
        }
    }

    const alertChange = () => {
        dispatch(
            showSweetAlertDialog({
                title: "Atenção !",
                text:
                    "Ao alterar a unidade do pedido, o preço dos itens também serão alterados.",
                type: "info",
                showConfirm: true,
            })
        )
    }

    const handleInput = (values, setFieldValue) => {
        try {
            let control = false
            let group_item_ids = []
            group_item_ids = group_item_ids.concat(
                process?.env?.REACT_APP_ITEM_EXAM_GROUP_IDS?.split(","),
                process?.env?.REACT_APP_ITEM_PROCEDIMENT_GROUP_IDS?.split(",")
            )

            for (let i = 0; i < values?.items?.length; i++) {
                let use = values?.items[i]

                if (!use?.item_id?.item?.group_id) {
                    continue
                }

                if (
                    group_item_ids.includes(
                        use?.item_id?.item?.group_id?.toString()
                    )
                ) {
                    control = true
                    break
                }
            }

            if (control) {
                setControlRequestProfDisabled(false)
            } else {
                // console.log("teste",values?.items)
                let verifyExistentGroupItem = values?.items?.filter(
                    e => e?.item_id?.item?.group_id
                )

                if (
                    verifyExistentGroupItem &&
                    verifyExistentGroupItem?.length > 0
                ) {
                    setFieldValue("request_prof_type", {
                        value: REQUEST_TYPES.NO_REQUEST_MEDICAL,
                        label: "Não",
                    })
                    setFieldValue("ind_professional_id", null)
                    setFieldValue("ind_professional_text", "")

                    setControlRequestProf(0)
                    setControlRequestProfDisabled(true)
                }
            }
        } catch (e) {
            console.log(e)
        }
    }
    const handleItemsToExamsOrders = async valuesForm => {
        try {
            let result = await getExamsOrdersBySalesOrder(id)

            if (result && result?.items?.length > 0) {
                handleModalExamsOrders(result, valuesForm)
            } else {
                dispatch(
                    showSweetAlertDialog({
                        title: "Atenção !",
                        text:
                            "Todos os exames desse pedido já foram solicitados!",
                        type: "info",
                        showConfirm: true,
                    })
                )
            }
        } catch (e) {
            console.log(e)
        }
    }
    const handleModalExamsOrders = (result, valuesForm) => {
        try {
            if (result) {
                let items = []
                let obj = {
                    patient: {
                        label: result?.patient?.name,
                        value: result?.patient?.id,
                    },
                    unit_id: valuesForm.unit_id,
                    sales_order_id: valuesForm.id,
                    request_professional_name:
                        valuesForm?.ind_professional_text,
                    request_professional_id: valuesForm?.ind_professional_id
                        ?.value
                        ? valuesForm?.ind_professional_id?.value
                        : null,
                }
                if (result?.items && result?.items?.length > 0) {
                    for (let i = 0; i < result?.items?.length; i++) {
                        let use = result?.items[i]

                        let objUse = {
                            id: use?.id,
                            item_id: {
                                label: use?.item?.name,
                                value: use?.item?.id,
                                item: {
                                    group_id: use?.item?.group_id,
                                },
                            },
                            quantity: use?.quantity,
                        }
                        items.push(objUse)
                    }
                }

                obj.items = items

                setControlModalExamRequest({
                    show: true,
                    data: obj,
                })
            }
        } catch (e) {
            console.log(e)
        }
    }

    const VerifyPatientHasSubscription = async patient_id => {
        if (!patient_id) return
        setLoadingVerifySubscription(true)
        try {
            let expand_vitta_cards = 1
            let getData = await findPatient(patient_id, expand_vitta_cards)
            if (getData?.expand_vitta_cards?.subscription?.subscription_id) {
                setPatientHasSubscription({
                    has: true,
                    data: getData,
                    error: false,
                })
            } else {
                setPatientHasSubscription({
                    has: false,
                    data: null,
                    error: false,
                })
            }
        } catch (e) {
            console.log(e)

            setPatientHasSubscription({
                has: false,
                data: null,
                error: true,
            })
        }

        setLoadingVerifySubscription(false)
    }

    const hasPermissionOfSurgeryGroupItem = useMemo(() => {
        let found = false
        for (let i = 0; i < User?.access_roles?.length; i++) {
            let record = User?.access_roles[i]
            for (let j = 0; j < record?.permissions?.length; j++) {
                let result = record?.permissions[j]
                if (result.name == "sell_surgery") {
                    found = true
                    break
                }
            }
        }

        if (found) {
            return true
        } else {
            false
        }
    }, [User])

    const hasPermissionToAddExamsOders = useMemo(() => {
        let found = false
        for (let i = 0; i < User?.access_roles?.length; i++) {
            let record = User?.access_roles[i]
            for (let j = 0; j < record?.permissions?.length; j++) {
                let result = record?.permissions[j]
                if (result.name == "add_exams_orders") {
                    found = true
                    break
                }
            }
        }

        if (found) {
            return true
        } else {
            false
        }
    }, [User])

    const hasPermissionExecItem = useMemo(() => {
        let found = false
        for (let i = 0; i < User?.access_roles?.length; i++) {
            let record = User?.access_roles[i]
            for (let j = 0; j < record?.permissions?.length; j++) {
                let result = record?.permissions[j]
                if (result.name == "add_service_exec") {
                    found = true
                    break
                }
            }
        }

        if (found) {
            return true
        } else {
            false
        }
    }, [User])

    // const controlPermissionApplyDiscount = useMemo(() => {
    //     let found = false
    //     for (let i = 0; i < User?.access_roles?.length; i++) {
    //         let record = User?.access_roles[i]
    //         for (let j = 0; j < record?.permissions?.length; j++) {
    //             let result = record?.permissions[j]
    //             if (result.name == "apply_discount") {
    //                 found = true
    //                 break
    //             }
    //         }
    //     }

    //     if (found) {
    //         return true
    //     } else {
    //         false
    //     }
    // }, [User])

    const sumDiscounts = items => {
        let totalDiscountsUser = 0
        let totalDiscountsVittaCard = 0
        let totalDiscountsVittanet = 0
        let totalRough = 0

        if (items && items?.length > 0) {
            for (let i = 0; i < items?.length; i++) {
                let item = items[i]
                let sumValueDiscountsVittaCard = 0
                let sumValueDiscountsUser = 0
                let sumValueDiscountsVittanet = 0
                if (item?.pricing && item?.pricing?.length > 0) {
                    for (let j = 0; j < item?.pricing?.length; j++) {
                        let itemPricing = item?.pricing[j]
                        if (itemPricing?.origin == "user") {
                            sumValueDiscountsUser +=
                                (itemPricing?.value / 100) *
                                itemPricing?.quantity
                        } else if (itemPricing?.origin == "vitta_card") {
                            sumValueDiscountsVittaCard +=
                                (itemPricing?.value / 100) *
                                itemPricing?.quantity
                        } else if (itemPricing?.origin == "vittanet") {
                            sumValueDiscountsVittanet +=
                                (itemPricing?.value / 100) *
                                itemPricing?.quantity
                        }
                    }

                    totalDiscountsUser =
                        totalDiscountsUser + sumValueDiscountsUser
                    totalDiscountsVittaCard =
                        totalDiscountsVittaCard + sumValueDiscountsVittaCard

                    totalDiscountsVittanet =
                        totalDiscountsVittanet + sumValueDiscountsVittanet
                }
                totalRough += item?.price_table * item?.quantity
            }
            // for (let i = 0; i < items?.length; i++) {
            //     let item = items[i]
            //     let sumValueDiscountsVittaCard = 0
            //     let sumValueDiscountsUser = 0

            //     if (item?.pricing && item?.pricing?.length > 0) {
            //         for (let j = 0; j < item?.pricing?.length; j++) {
            //             let itemPricing = item?.pricing[j]
            //             if (itemPricing?.origin == "user") {
            //                 sumValueDiscountsUser += itemPricing?.value / 100
            //             } else {
            //                 sumValueDiscountsVittaCard +=
            //                     itemPricing?.value / 100
            //             }
            //         }

            //         totalDiscountsUser =
            //             totalDiscountsUser +
            //             sumValueDiscountsUser * item?.quantity
            //         totalDiscountsVittaCard =
            //             totalDiscountsVittaCard +
            //             sumValueDiscountsVittaCard * item?.quantity
            //     }
            //     totalRough += item?.price_table * item?.quantity
            // }
        }

        return (
            <div
                style={{
                    color: "#0195d5",
                    fontWeight: "bold",
                }}
            >
                {totalRough > 0 ? (
                    <div>
                        {" "}
                        - Total Bruto:{" "}
                        {totalRough.toLocaleString("pt-br", {
                            style: "currency",
                            currency: "BRL",
                        })}{" "}
                    </div>
                ) : (
                    ""
                )}
                {totalDiscountsUser > 0 ? (
                    <div>
                        {" "}
                        - Total Desconto Aplicado Usuário:{" "}
                        {totalDiscountsUser.toLocaleString("pt-br", {
                            style: "currency",
                            currency: "BRL",
                        })}{" "}
                    </div>
                ) : (
                    ""
                )}
                {totalDiscountsVittaCard > 0 ? (
                    <div>
                        {" "}
                        - Total Desconto Aplicado Vittá + Vantagens:{" "}
                        {totalDiscountsVittaCard.toLocaleString("pt-br", {
                            style: "currency",
                            currency: "BRL",
                        })}{" "}
                    </div>
                ) : (
                    ""
                )}
                {totalDiscountsVittanet > 0 ? (
                    <div>
                        {" "}
                        - Total Desconto Aplicado VittáNet:{" "}
                        {totalDiscountsVittanet.toLocaleString("pt-br", {
                            style: "currency",
                            currency: "BRL",
                        })}{" "}
                    </div>
                ) : (
                    ""
                )}
            </div>
        )
    }

    const handleDisablePriceField = (optionItem, name, setFieldValue) => {
        if (!hasPermissionOfSurgeryGroupItem) return
        let data = { ...optionItem }
        data.showInputPriceDisabled = true
        setFieldValue(name, data)
    }
    const renderRecordExamModal = async id => {
        try {
            let result = await getById(id)
            if (result) {
                let items = []
                let obj = {
                    id: id,
                    partner_order_id: result?.partner_order_id,
                    patient: {
                        label: result?.patient?.name,
                        value: result?.patient?.id,
                    },
                    partner: {
                        label: result?.partner?.name,
                        value: result?.partner?.id,
                    },
                    status: result?.status,
                    partner_status_integration:
                        result?.partner_status_integration,
                    partner_status: result?.partner_status,
                    partner_obs: result?.partner_obs,
                    recipients: result?.recipients,
                }
                if (result?.items && result?.items?.length > 0) {
                    for (let i = 0; i < result?.items?.length; i++) {
                        let use = result?.items[i]

                        let objUse = {
                            id: use?.id,
                            item_id: {
                                label: use?.item_name,
                                value: use?.item_id,
                                item: {
                                    group_id: use?.item?.group_id,
                                },
                            },
                            quantity: use?.quantity,
                            status: use?.status,
                            additional_data: use?.additional_data,
                            print_bar_code_command: use?.print_bar_code_command,
                            partner_status_id: use?.partner_status_id,
                            partner_status: use?.partner_status,
                            partner_obs: use?.partner_obs,
                        }
                        items.push(objUse)
                    }
                }

                obj.items = items

                setControlModalExamRequest({
                    show: true,
                    data: obj,
                })

                return obj
            }
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs
                        title={
                            pageSalesDocType == SALES_DOCS_TYPE.SALES_QUOTE
                                ? "Orçamentos"
                                : pageSalesDocType ==
                                  SALES_DOCS_TYPE.SALES_ORDER
                                ? "Pedidos"
                                : "Notas Fiscais"
                        }
                        breadcrumbItem={
                            (id ? "Alterar " : "Adicionar ") +
                            (pageSalesDocType == SALES_DOCS_TYPE.SALES_QUOTE
                                ? "Orçamento"
                                : pageSalesDocType ==
                                  SALES_DOCS_TYPE.SALES_ORDER
                                ? "Pedido"
                                : "Nota Fiscal")
                        }
                    />
                    <Formik
                        initialValues={pageData.formOrderValues}
                        enableReinitialize={true}
                        onSubmit={onSubmit}
                    >
                        {({
                            values,
                            isSubmitting,
                            dirty,
                            setFieldValue,
                            submitForm,
                        }) => (
                            <Form>
                                <Row>
                                    <Col xs="12">
                                        <Card>
                                            <CardBody>
                                                <Row className="mb-3 d-none d-print-block">
                                                    <Col
                                                        md="8"
                                                        className="d-flex justify-content-start align-items-center"
                                                    >
                                                        <img
                                                            src={logovitta}
                                                            alt=""
                                                            height="95px"
                                                            className="mb-3"
                                                        />

                                                        <div>
                                                            <h4>
                                                                Unidade{" "}
                                                                {
                                                                    values
                                                                        .unit_id
                                                                        ?.label
                                                                }
                                                            </h4>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <CardTitle>
                                                    Proposta de Venda
                                                </CardTitle>
                                                <Row>
                                                    <Col md="1">
                                                        <FormGroup>
                                                            <Label>ID</Label>
                                                            <FastField
                                                                name={`id`}
                                                                value={
                                                                    values.id
                                                                }
                                                                component={
                                                                    TextInput
                                                                }
                                                                readOnly={true}
                                                                disabled={true}
                                                                isDisabled={
                                                                    true
                                                                }
                                                                style={{
                                                                    paddingLeft:
                                                                        "5px",
                                                                    paddingRight:
                                                                        "0px",
                                                                    backgroundColor:
                                                                        "#f2f2f2",
                                                                }}
                                                            />
                                                        </FormGroup>
                                                    </Col>

                                                    <Col md="2">
                                                        <FormGroup>
                                                            <Label>
                                                                Unidade
                                                            </Label>

                                                            <Field
                                                                name="unit_id"
                                                                placeholder="Selecionar Unidade"
                                                                isClearable
                                                                defaultValue={
                                                                    values.unit_id
                                                                }
                                                                options={
                                                                    unitsOptions
                                                                }
                                                                RefUnit={
                                                                    inputRefUnit
                                                                }
                                                                component={
                                                                    ReactSelectInput
                                                                }
                                                                refreshOptions={
                                                                    refreshOptions
                                                                }
                                                                valuesForm={
                                                                    values
                                                                }
                                                                alertChange={
                                                                    alertChange
                                                                }
                                                                isDisabled={
                                                                    pageData
                                                                        .formOrderValues
                                                                        .onlyView ||
                                                                    id
                                                                }
                                                            />
                                                        </FormGroup>
                                                    </Col>

                                                    <Col md="4">
                                                        <FormGroup>
                                                            <Label>
                                                                Paciente{" "}
                                                                {values
                                                                    ?.patient_id
                                                                    ?.value ? (
                                                                    <i
                                                                        className="fas fa-user text-info"
                                                                        style={{
                                                                            cursor:
                                                                                "pointer",
                                                                            position:
                                                                                "relative",
                                                                            top:
                                                                                "-1px",
                                                                        }}
                                                                        onClick={() => {
                                                                            if (
                                                                                values
                                                                                    ?.patient_id
                                                                                    ?.value
                                                                            ) {
                                                                                window.open(
                                                                                    `/pacientes/${values?.patient_id?.value}/dados`,
                                                                                    "_blank"
                                                                                )
                                                                            }
                                                                        }}
                                                                    ></i>
                                                                ) : null}
                                                                {showLoadingVerifySubscription ? (
                                                                    <>
                                                                        {" "}
                                                                        <small className="text-info">
                                                                            Verificando
                                                                            assinaturas...
                                                                        </small>{" "}
                                                                        <Spinner
                                                                            size={
                                                                                "sm"
                                                                            }
                                                                            color="info"
                                                                            style={{
                                                                                position:
                                                                                    "relative",
                                                                                top:
                                                                                    "-3px",
                                                                            }}
                                                                        />
                                                                    </>
                                                                ) : null}
                                                            </Label>
                                                            {!showLoadingVerifySubscription &&
                                                            patientHasSubscription?.has ? (
                                                                <Badge
                                                                    onClick={() => {
                                                                        setModalBenefits(
                                                                            {
                                                                                show: true,
                                                                                data:
                                                                                    patientHasSubscription
                                                                                        ?.data
                                                                                        ?.expand_vitta_cards,
                                                                            }
                                                                        )
                                                                    }}
                                                                    pill
                                                                    className="ml-2 badge-soft-success"
                                                                    style={{
                                                                        cursor:
                                                                            "pointer",
                                                                        fontSize:
                                                                            "12px",
                                                                    }}
                                                                >
                                                                    Vittá{" "}
                                                                    <i className="fas fa-plus"></i>{" "}
                                                                    Vantagens
                                                                </Badge>
                                                            ) : patientHasSubscription.error ? (
                                                                <Badge
                                                                    pill
                                                                    className="ml-2 badge-soft-danger"
                                                                    style={{
                                                                        cursor:
                                                                            "pointer",
                                                                        fontSize:
                                                                            "12px",
                                                                    }}
                                                                >
                                                                    Sem conexão
                                                                    com os
                                                                    serviços
                                                                    Vittá{" "}
                                                                    <i className="fas fa-plus"></i>
                                                                </Badge>
                                                            ) : null}

                                                            <Field
                                                                name="patient_id"
                                                                placeholder="Selecionar Paciente"
                                                                cacheOptions
                                                                defaultOptions
                                                                isClearable
                                                                RefPatient={
                                                                    inputRefPatient
                                                                }
                                                                valuesForm={
                                                                    values
                                                                }
                                                                defaultValue={
                                                                    values.patient_id
                                                                }
                                                                loadOptions={
                                                                    debounceOptionsPatient
                                                                }
                                                                component={
                                                                    AsyncSelectInput
                                                                }
                                                                VerifyPatientHasSubscription={
                                                                    VerifyPatientHasSubscription
                                                                }
                                                                isDisabled={
                                                                    pageData
                                                                        .formOrderValues
                                                                        .onlyView ||
                                                                    id
                                                                }
                                                            />
                                                        </FormGroup>
                                                    </Col>

                                                    <Col md="2" align="start">
                                                        <FormGroup>
                                                            <Label>
                                                                Solicitação
                                                                Médica
                                                            </Label>
                                                            <Field
                                                                name={`request_prof_type`}
                                                                id={`request_prof_type`}
                                                                placeholder="Selecione uma opção"
                                                                state={
                                                                    setControlRequestProf
                                                                }
                                                                component={
                                                                    ReactSelectInput
                                                                }
                                                                valuesForm={
                                                                    values
                                                                }
                                                                options={
                                                                    prof_type_options
                                                                }
                                                                isDisabled={
                                                                    pageData
                                                                        .formOrderValues
                                                                        .onlyView ||
                                                                    controlRequestProfDisabled ||
                                                                    pageData?.medical_exams_id
                                                                }
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    {pageData?.doc
                                                        ?.service_type !=
                                                    SERVICES_TYPES.DENTAL ? (
                                                        <Col md="3">
                                                            {controlRequestProf ==
                                                            1 ? (
                                                                <FormGroup>
                                                                    <Label>
                                                                        Médico
                                                                        Solicitante
                                                                    </Label>
                                                                    <FastField
                                                                        name={`ind_professional_id`}
                                                                        placeholder="Busque o profissional"
                                                                        cacheOptions
                                                                        defaultOptions
                                                                        isClearable
                                                                        loadOptions={
                                                                            debounceOptionsProfessional
                                                                        }
                                                                        classNamePrefix="select2-selection"
                                                                        defaultValue={
                                                                            values.ind_professional_id
                                                                        }
                                                                        component={
                                                                            AsyncSelectInput
                                                                        }
                                                                        isDisabled={
                                                                            pageData
                                                                                .formOrderValues
                                                                                .onlyView ||
                                                                            pageData?.medical_exams_id
                                                                        }
                                                                    />
                                                                </FormGroup>
                                                            ) : controlRequestProf ==
                                                              2 ? (
                                                                <FormGroup>
                                                                    <Label>
                                                                        Médico
                                                                        Solicitante
                                                                    </Label>
                                                                    <FastField
                                                                        name={`ind_professional_text`}
                                                                        placeholder="Digite o nome do profissional"
                                                                        component={
                                                                            TextInput
                                                                        }
                                                                        style={{
                                                                            backgroundColor: pageData
                                                                                .formOrderValues
                                                                                .onlyView
                                                                                ? "#f2f2f2"
                                                                                : "",
                                                                        }}
                                                                        disabled={
                                                                            pageData
                                                                                .formOrderValues
                                                                                .onlyView
                                                                        }
                                                                        defaultValue={
                                                                            values?.ind_professional_text
                                                                        }
                                                                    />
                                                                </FormGroup>
                                                            ) : null}
                                                        </Col>
                                                    ) : null}

                                                    {pageData?.doc
                                                        ?.assessment_id ? (
                                                        <Col
                                                            sm="3"
                                                            align="center"
                                                            className="mt-4"
                                                        >
                                                            {" "}
                                                            <a
                                                                target="_blank"
                                                                href={`/avaliacoes/${pageData?.doc?.assessment_id}`}
                                                                className="btn-sm btn-info"
                                                                style={{
                                                                    position:
                                                                        "relative",
                                                                    top: "10px",
                                                                    left:
                                                                        "-17px",
                                                                }}
                                                            >
                                                                {" "}
                                                                <i className="fas fa-tooth mr-2"></i>
                                                                Avaliação
                                                                odontológica{" "}
                                                            </a>{" "}
                                                        </Col>
                                                    ) : null}
                                                </Row>

                                                <Row>
                                                    <Col md="5">
                                                        <FormGroup>
                                                            <Label>
                                                                Observações
                                                            </Label>
                                                            <FastField
                                                                name="obs"
                                                                rows={2}
                                                                component={
                                                                    TextAreaInput
                                                                }
                                                                style={{
                                                                    backgroundColor: pageData
                                                                        .formOrderValues
                                                                        .onlyView
                                                                        ? "#f2f2f2"
                                                                        : "",
                                                                }}
                                                                readOnly={
                                                                    pageData
                                                                        .formOrderValues
                                                                        .onlyView
                                                                }
                                                            />
                                                        </FormGroup>
                                                    </Col>

                                                    {pageSalesDocType ==
                                                    SALES_DOCS_TYPE.SALES_ORDER ? (
                                                        <Col
                                                            sm="2"
                                                            className="d-flex align-items-center d-print-none"
                                                        >
                                                            {[
                                                                SALES_ORDER_STATUS.AWAITING_PAYMENT,
                                                                SALES_ORDER_STATUS.PAID,
                                                                SALES_ORDER_STATUS.INVOICE_ISSUED,
                                                                SALES_ORDER_STATUS.CANCELED,
                                                                SALES_ORDER_STATUS.PARTIALLY_PAID,
                                                            ].includes(
                                                                values.status
                                                            ) ? (
                                                                <Button
                                                                    type="button"
                                                                    style={{
                                                                        position:
                                                                            "relative",
                                                                        top:
                                                                            "-3px",
                                                                        width:
                                                                            "100%",
                                                                    }}
                                                                    color="primary"
                                                                    onClick={() => {
                                                                        setModalPaymentData(
                                                                            {
                                                                                show: true,
                                                                                data:
                                                                                    pageData.doc,
                                                                            }
                                                                        )
                                                                    }}
                                                                >
                                                                    Recebimento
                                                                </Button>
                                                            ) : null}

                                                            {[
                                                                SALES_ORDER_STATUS.PAID,
                                                                SALES_ORDER_STATUS.INVOICE_ISSUED,
                                                            ].includes(
                                                                values.status
                                                            ) &&
                                                            pageData
                                                                .formOrderValues
                                                                ?.os?.id ? (
                                                                <Link
                                                                    to={
                                                                        "/ordens-servico/" +
                                                                        pageData
                                                                            .formOrderValues
                                                                            .os
                                                                            .id
                                                                    }
                                                                    className="text-primary"
                                                                    target="_blank"
                                                                >
                                                                    <Button
                                                                        color="primary"
                                                                        onClick={() => {
                                                                            console.log()
                                                                        }}
                                                                    >
                                                                        OS
                                                                    </Button>
                                                                </Link>
                                                            ) : null}
                                                        </Col>
                                                    ) : null}
                                                    {id ? (
                                                        <Col sm="2">
                                                            <FormGroup>
                                                                <Label>
                                                                    Data pedido
                                                                </Label>{" "}
                                                                {/* Visível somente em tamanhos de tela xs, sm */}
                                                                <FastField
                                                                    name="created_at"
                                                                    defaultValue={
                                                                        values?.created_at
                                                                    }
                                                                    style={{
                                                                        backgroundColor:
                                                                            "#f2f2f2",
                                                                    }}
                                                                    readOnly={
                                                                        true
                                                                    }
                                                                    component={
                                                                        DateInput
                                                                    }
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    ) : null}
                                                    {values.id ? (
                                                        <Col
                                                            md="2"
                                                            align="center"
                                                            className="mt-3 ml-5"
                                                        >
                                                            <FormGroup>
                                                                <Label>
                                                                    Status{" "}
                                                                    {pageSalesDocType ==
                                                                    SALES_DOCS_TYPE.SALES_ORDER
                                                                        ? "Pedido"
                                                                        : "Orçamento"}
                                                                </Label>
                                                                <div>
                                                                    {pageSalesDocType ==
                                                                    SALES_DOCS_TYPE.SALES_QUOTE
                                                                        ? renderBadgeSalesQuoteStatus(
                                                                              values.status
                                                                          )
                                                                        : pageSalesDocType ==
                                                                          SALES_DOCS_TYPE.SALES_ORDER
                                                                        ? renderBadgeSalesOrderStatus(
                                                                              values.status
                                                                          )
                                                                        : ""}
                                                                </div>
                                                            </FormGroup>
                                                        </Col>
                                                    ) : null}
                                                </Row>
                                            </CardBody>
                                        </Card>
                                        <Card>
                                            <CardBody>
                                                <Row>
                                                    <Col sm="6">
                                                        {" "}
                                                        <CardTitle>
                                                            Itens da Venda
                                                        </CardTitle>
                                                        <CardSubtitle className=""></CardSubtitle>
                                                    </Col>{" "}
                                                    <Col
                                                        sm="6"
                                                        align="end"
                                                        className="d-print-none"
                                                    >
                                                        {" "}
                                                        <UncontrolledDropdown>
                                                            <DropdownToggle
                                                                // href="#"
                                                                className="card-drop"
                                                                tag="i"
                                                            >
                                                                <i
                                                                    className="bx bx-list-ul ml-2"
                                                                    style={{
                                                                        color:
                                                                            "#0195d5",
                                                                        fontSize:
                                                                            "30px",
                                                                        fontWeight:
                                                                            "bold",
                                                                        cursor:
                                                                            "pointer",
                                                                    }}
                                                                />
                                                            </DropdownToggle>

                                                            {!id ||
                                                            !pageData?.doc
                                                                ?.service_type ||
                                                            SERVICES_TYPES.CLINICAL ==
                                                                pageData?.doc
                                                                    ?.service_type ? (
                                                                <DropdownMenu
                                                                    right
                                                                >
                                                                    <DropdownItem
                                                                        className="text-info"
                                                                        onClick={() => {
                                                                            setModalPricingAllItems(
                                                                                {
                                                                                    show: true,
                                                                                    data: values,
                                                                                    refreshItemsForm: newItems => {
                                                                                        setFieldValue(
                                                                                            `items`,
                                                                                            newItems
                                                                                        )
                                                                                    },
                                                                                }
                                                                            )
                                                                        }}
                                                                    >
                                                                        <i className="fas fa-exchange-alt text-info mr-2" />
                                                                        Aplicar
                                                                        desconto
                                                                        em massa
                                                                    </DropdownItem>
                                                                    {(SALES_ORDER_STATUS.PAID ==
                                                                        values?.status ||
                                                                        SALES_ORDER_STATUS.INVOICE_ISSUED ==
                                                                            values?.status) &&
                                                                    values?.items.find(
                                                                        e =>
                                                                            process
                                                                                ?.env
                                                                                ?.REACT_APP_ITEM_EXAM_LABORATORY_GROUP_IDS &&
                                                                            process?.env?.REACT_APP_ITEM_EXAM_LABORATORY_GROUP_IDS.includes(
                                                                                e
                                                                                    .item_id
                                                                                    ?.item
                                                                                    ?.group_id
                                                                            )
                                                                    ) ? (
                                                                        <div>
                                                                            {" "}
                                                                            {hasPermissionToAddExamsOders ? (
                                                                                <DropdownItem
                                                                                    className="text-info"
                                                                                    onClick={() => {
                                                                                        handleItemsToExamsOrders(
                                                                                            values
                                                                                        )
                                                                                    }}
                                                                                >
                                                                                    <i className="fas fa-exchange-alt text-info mr-2" />
                                                                                    Solicitar
                                                                                    Exames
                                                                                </DropdownItem>
                                                                            ) : (
                                                                                ""
                                                                            )}
                                                                            <DropdownItem
                                                                                className="text-info"
                                                                                target="_blank"
                                                                                href={
                                                                                    "/pacientes/" +
                                                                                    values
                                                                                        ?.patient_id
                                                                                        ?.value +
                                                                                    "/pedidos-exame?sales_order_id=" +
                                                                                    id
                                                                                }
                                                                            >
                                                                                <i className="fas fa-eye text-info mr-2" />
                                                                                Visualizar
                                                                                Exames
                                                                            </DropdownItem>
                                                                        </div>
                                                                    ) : (
                                                                        ""
                                                                    )}
                                                                </DropdownMenu>
                                                            ) : // <DropdownMenu
                                                            //     right
                                                            // >
                                                            //     <DropdownItem
                                                            //         className="text-info"
                                                            //         onClick={() => {
                                                            //             setModalAppointmentData(
                                                            //                 {
                                                            //                     show: true,
                                                            //                     data: {
                                                            //                         fit: true,
                                                            //                         sales_order: id,
                                                            //                         appointment_book: null,
                                                            //                         unit: {
                                                            //                             id:
                                                            //                                 pageData
                                                            //                                     .formOrderValues
                                                            //                                     ?.unit_id
                                                            //                                     ?.value,
                                                            //                             name:
                                                            //                                 pageData
                                                            //                                     .formOrderValues
                                                            //                                     ?.unit_id
                                                            //                                     ?.label,
                                                            //                         },
                                                            //                         patient_data: {
                                                            //                             id:
                                                            //                                 values
                                                            //                                     ?.patient_id
                                                            //                                     ?.value,
                                                            //                             name:
                                                            //                                 values
                                                            //                                     ?.patient_id
                                                            //                                     ?.label,
                                                            //                         },
                                                            //                     },
                                                            //                 }
                                                            //             )
                                                            //         }}
                                                            //     >
                                                            //         <i className="far fa-calendar text-info mr-2" />
                                                            //         Criar
                                                            //         Agendamento
                                                            //     </DropdownItem>
                                                            //     <DropdownItem
                                                            //         className="text-info"
                                                            //         onClick={()=>{
                                                            //         setModalHistoricAppointments({
                                                            //             show:true
                                                            //         })
                                                            //         }}
                                                            //     >
                                                            //         <i className="fas fa-book text-info mr-2" />
                                                            //        Histórico de Agendamentos
                                                            //     </DropdownItem>
                                                            // </DropdownMenu>
                                                            null}
                                                        </UncontrolledDropdown>
                                                    </Col>
                                                </Row>

                                                <br />

                                                <Row
                                                    style={{
                                                        paddingLeft: "8px",
                                                    }}
                                                >
                                                    <Col
                                                        xs="12"
                                                        md={"5"}
                                                        className="d-none d-md-block"
                                                        style={{
                                                            paddingRight: "0px",
                                                        }}
                                                    >
                                                        <Label>Item</Label>
                                                    </Col>
                                                    <Col
                                                        xs="12"
                                                        md={"2"}
                                                        className="d-none d-md-block"
                                                        style={{
                                                            paddingLeft: "3px",
                                                            paddingRight: "0px",
                                                        }}
                                                    >
                                                        <Label>
                                                            Prc. Tabela
                                                        </Label>
                                                    </Col>
                                                    <Col
                                                        xs="12"
                                                        md={"2"}
                                                        className="d-none d-md-block"
                                                        style={{
                                                            paddingLeft: "3px",
                                                            paddingRight: "0px",
                                                        }}
                                                    >
                                                        <Label>Quant.</Label>
                                                    </Col>
                                                    <Col
                                                        xs="12"
                                                        md={"2"}
                                                        className="d-none d-md-block"
                                                        style={{
                                                            paddingLeft: "3px",
                                                            paddingRight: "0px",
                                                        }}
                                                    >
                                                        <Label>
                                                            Preço Unit.
                                                        </Label>
                                                    </Col>
                                                    {/* {pageData?.doc
                                                        ?.service_type &&
                                                    pageData?.doc
                                                        ?.service_type ==
                                                        SERVICES_TYPES.DENTAL ? (
                                                        ""
                                                    ) : (
                                                        <>
                                                            <Col
                                                                xs="12"
                                                                md="2"
                                                                className="d-none d-md-block"
                                                                style={{
                                                                    paddingLeft:
                                                                        "3px",
                                                                    paddingRight:
                                                                        "0px",
                                                                }}
                                                            >
                                                                <Label>
                                                                    Data
                                                                    Execução
                                                                </Label>
                                                            </Col>
                                                            <Col
                                                                xs="12"
                                                                md="3"
                                                                className="d-none d-md-block"
                                                                style={{
                                                                    paddingLeft:
                                                                        "3px",
                                                                    paddingRight:
                                                                        "0px",
                                                                }}
                                                            >
                                                                <Label>
                                                                    Profissional
                                                                    Execução
                                                                </Label>
                                                            </Col>
                                                        </>
                                                    )} */}
                                                    <Col
                                                        xs="12"
                                                        md="1"
                                                        className="d-none d-md-block d-print-none"
                                                        style={{
                                                            paddingLeft: "5px",
                                                            paddingRight: "0px",
                                                        }}
                                                    >
                                                        <Label>Ações</Label>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col
                                                        lg="12"
                                                        className="mt-2"
                                                    >
                                                        <FieldArray
                                                            name="items"
                                                            render={arrayHelpers => (
                                                                <div>
                                                                    {values &&
                                                                    values
                                                                        ?.items
                                                                        ?.length >
                                                                        0
                                                                        ? values?.items?.map(
                                                                              (
                                                                                  item,
                                                                                  index
                                                                              ) => (
                                                                                  <Row
                                                                                      key={
                                                                                          index
                                                                                      }
                                                                                      className=" align-items-center"
                                                                                      style={{
                                                                                          paddingLeft:
                                                                                              "12px",
                                                                                      }}
                                                                                  >
                                                                                      <Col
                                                                                          xs="12"
                                                                                          md={
                                                                                              "5"
                                                                                          }
                                                                                          style={{
                                                                                              paddingLeft:
                                                                                                  "3px",
                                                                                              paddingRight:
                                                                                                  "0px",
                                                                                          }}
                                                                                      >
                                                                                          <FormGroup
                                                                                              style={{
                                                                                                  fontSize:
                                                                                                      "0.7rem",
                                                                                              }}
                                                                                          >
                                                                                              <Label className="d-block d-md-none">
                                                                                                  Item
                                                                                              </Label>{" "}
                                                                                              {/* Visível somente em tamanhos de tela xs, sm */}
                                                                                              <FastField
                                                                                                  name={`items[${index}].item_id`}
                                                                                                  placeholder="Selecione o Item"
                                                                                                  loadOptions={
                                                                                                      debounceOptionsItem
                                                                                                  }
                                                                                                  classNamePrefix="select2-selection"
                                                                                                  defaultValue={
                                                                                                      item.item_id
                                                                                                  }
                                                                                                  RefUnit={
                                                                                                      inputRefUnit
                                                                                                  }
                                                                                                  component={
                                                                                                      AsyncSelectInputItemService
                                                                                                  }
                                                                                                  input_item_index={
                                                                                                      index
                                                                                                  }
                                                                                                  handleInput={
                                                                                                      handleInput
                                                                                                  }
                                                                                                  valuesForm={
                                                                                                      values
                                                                                                  }
                                                                                                  valuesItem={
                                                                                                      item
                                                                                                  }
                                                                                                  handleDisablePriceField={
                                                                                                      handleDisablePriceField
                                                                                                  }
                                                                                                  dispatch={
                                                                                                      dispatch
                                                                                                  }
                                                                                                  arrayHelpers={
                                                                                                      arrayHelpers
                                                                                                  }
                                                                                                  price_input={`items[${index}].price`}
                                                                                                  isDisabled={
                                                                                                      pageData
                                                                                                          .formOrderValues
                                                                                                          .onlyView
                                                                                                  }
                                                                                                  style={{
                                                                                                      fontSize:
                                                                                                          "0.7rem",
                                                                                                  }}
                                                                                              />
                                                                                          </FormGroup>
                                                                                      </Col>

                                                                                      <Col
                                                                                          xs="12"
                                                                                          md={
                                                                                              "2"
                                                                                          }
                                                                                          style={{
                                                                                              paddingLeft:
                                                                                                  "3px",
                                                                                              paddingRight:
                                                                                                  "0px",
                                                                                          }}
                                                                                      >
                                                                                          <FormGroup className="input-currency-order">
                                                                                              <Label className="d-block d-md-none">
                                                                                                  Preço
                                                                                                  Tabela
                                                                                              </Label>{" "}
                                                                                              <FastField
                                                                                                  name={`items[${index}].price_table`}
                                                                                                  defaultValue={
                                                                                                      item?.price_table
                                                                                                  }
                                                                                                  displayType={
                                                                                                      "input"
                                                                                                  }
                                                                                                  thousandSeparator={
                                                                                                      "."
                                                                                                  }
                                                                                                  decimalSeparator={
                                                                                                      ","
                                                                                                  }
                                                                                                  decimalScale={
                                                                                                      2
                                                                                                  }
                                                                                                  fixedDecimalScale={
                                                                                                      true
                                                                                                  }
                                                                                                  allowEmptyFormatting={
                                                                                                      true
                                                                                                  }
                                                                                                  prefix={
                                                                                                      "R$ "
                                                                                                  }
                                                                                                  className="form-control"
                                                                                                  formValues={
                                                                                                      values
                                                                                                  }
                                                                                                  component={
                                                                                                      CurrencyInput
                                                                                                  }
                                                                                                  disabled
                                                                                                  style={{
                                                                                                      fontSize:
                                                                                                          "0.7rem",
                                                                                                      paddingLeft:
                                                                                                          "2px",
                                                                                                      paddingRight:
                                                                                                          "0px",
                                                                                                      backgroundColor:
                                                                                                          "#f2f2f2",
                                                                                                  }}
                                                                                              />
                                                                                          </FormGroup>
                                                                                      </Col>

                                                                                      <Col
                                                                                          xs="12"
                                                                                          md={
                                                                                              "2"
                                                                                          }
                                                                                          style={{
                                                                                              paddingLeft:
                                                                                                  "3px",
                                                                                              paddingRight:
                                                                                                  "0px",
                                                                                          }}
                                                                                      >
                                                                                          <FormGroup>
                                                                                              <Label className="d-block d-md-none">
                                                                                                  Quantidade
                                                                                              </Label>{" "}
                                                                                              {/* Visível somente em tamanhos de tela xs, sm */}
                                                                                              <FastField
                                                                                                  name={`items[${index}].quantity`}
                                                                                                  defaultValue={
                                                                                                      item?.quantity
                                                                                                  }
                                                                                                  component={
                                                                                                      NumberInput
                                                                                                  }
                                                                                                  className="form-control"
                                                                                                  readOnly={
                                                                                                      pageData
                                                                                                          ?.formOrderValues
                                                                                                          ?.onlyView
                                                                                                  }
                                                                                                  style={{
                                                                                                      fontSize:
                                                                                                          "0.7rem",
                                                                                                      backgroundColor: pageData
                                                                                                          ?.formOrderValues
                                                                                                          ?.onlyView
                                                                                                          ? "#f2f2f2"
                                                                                                          : "",
                                                                                                  }}
                                                                                              />
                                                                                          </FormGroup>
                                                                                      </Col>
                                                                                      <Col
                                                                                          xs="12"
                                                                                          md={
                                                                                              "2"
                                                                                          }
                                                                                          style={{
                                                                                              paddingLeft:
                                                                                                  "3px",
                                                                                              paddingRight:
                                                                                                  "0px",
                                                                                          }}
                                                                                      >
                                                                                          <FormGroup>
                                                                                              <Label className="d-block d-md-none">
                                                                                                  Preço
                                                                                                  Unitário
                                                                                              </Label>{" "}
                                                                                              {/* Visível somente  em tamanhos de tela xs, sm */}
                                                                                              {!item
                                                                                                  ?.item_id
                                                                                                  ?.showInputPriceDisabled ? (
                                                                                                  <FastField
                                                                                                      name={`items[${index}].price`}
                                                                                                      defaultValue={
                                                                                                          item.price
                                                                                                      }
                                                                                                      displayType={
                                                                                                          "input"
                                                                                                      }
                                                                                                      thousandSeparator={
                                                                                                          "."
                                                                                                      }
                                                                                                      decimalSeparator={
                                                                                                          ","
                                                                                                      }
                                                                                                      decimalScale={
                                                                                                          2
                                                                                                      }
                                                                                                      fixedDecimalScale={
                                                                                                          true
                                                                                                      }
                                                                                                      allowEmptyFormatting={
                                                                                                          true
                                                                                                      }
                                                                                                      prefix={
                                                                                                          "R$ "
                                                                                                      }
                                                                                                      className={`form-control`}
                                                                                                      component={
                                                                                                          CurrencyInput
                                                                                                      }
                                                                                                      disabled={
                                                                                                          true
                                                                                                      }
                                                                                                      style={{
                                                                                                          paddingLeft:
                                                                                                              "2px",
                                                                                                          paddingRight:
                                                                                                              "0px",
                                                                                                          fontSize:
                                                                                                              "0.7rem",
                                                                                                          backgroundColor:
                                                                                                              "#f2f2f2",
                                                                                                      }}
                                                                                                  />
                                                                                              ) : (
                                                                                                  <FastField
                                                                                                      name={`items[${index}].price`}
                                                                                                      defaultValue={
                                                                                                          item.price
                                                                                                      }
                                                                                                      displayType={
                                                                                                          "input"
                                                                                                      }
                                                                                                      thousandSeparator={
                                                                                                          "."
                                                                                                      }
                                                                                                      decimalSeparator={
                                                                                                          ","
                                                                                                      }
                                                                                                      decimalScale={
                                                                                                          2
                                                                                                      }
                                                                                                      fixedDecimalScale={
                                                                                                          true
                                                                                                      }
                                                                                                      allowEmptyFormatting={
                                                                                                          true
                                                                                                      }
                                                                                                      prefix={
                                                                                                          "R$ "
                                                                                                      }
                                                                                                      className={`form-control`}
                                                                                                      component={
                                                                                                          CurrencyInput
                                                                                                      }
                                                                                                      style={{
                                                                                                          paddingLeft:
                                                                                                              "2px",
                                                                                                          paddingRight:
                                                                                                              "0px",
                                                                                                          fontSize:
                                                                                                              "0.7rem",
                                                                                                      }}
                                                                                                  />
                                                                                              )}
                                                                                          </FormGroup>
                                                                                      </Col>
                                                                                      {/* {pageData
                                                                                          ?.doc
                                                                                          ?.service_type &&
                                                                                      pageData
                                                                                          ?.doc
                                                                                          ?.service_type ==
                                                                                          SERVICES_TYPES.DENTAL ? 
                                                                                          null
                                                                                       : 
                                                                                          <>
                                                                                              <Col
                                                                                                  xs="12"
                                                                                                  md="2"
                                                                                                  style={{
                                                                                                      paddingLeft:
                                                                                                          "3px",
                                                                                                      paddingRight:
                                                                                                          "0px",
                                                                                                  }}
                                                                                              >
                                                                                                  <FormGroup>
                                                                                                      <Label className="d-block d-md-none">
                                                                                                          Previsão
                                                                                                          Execução
                                                                                                      </Label>{" "}
                                                                                                     
                                                                                                      <FastField
                                                                                                          id={
                                                                                                              "execin" +
                                                                                                              index
                                                                                                          }
                                                                                                          name={`items[${index}].exec_in`}
                                                                                                          defaultValue={
                                                                                                              item.exec_in
                                                                                                          }
                                                                                                          component={
                                                                                                              DateInput
                                                                                                          }
                                                                                                          className={`form-control`}
                                                                                                          readOnly={
                                                                                                              pageData
                                                                                                                  .formOrderValues
                                                                                                                  .onlyView
                                                                                                          }
                                                                                                          style={{
                                                                                                              fontSize:
                                                                                                                  "0.7rem",
                                                                                                              backgroundColor: pageData
                                                                                                                  ?.formOrderValues
                                                                                                                  ?.onlyView
                                                                                                                  ? "#f2f2f2"
                                                                                                                  : "",
                                                                                                              borderColor:
                                                                                                                  item?.mark_exec_at &&
                                                                                                                  pageData
                                                                                                                      ?.formOrderValues
                                                                                                                      ?.onlyView
                                                                                                                      ? "#0195d5"
                                                                                                                      : pageData
                                                                                                                            ?.formOrderValues
                                                                                                                            ?.onlyView
                                                                                                                      ? "#f1b54f"
                                                                                                                      : "",
                                                                                                              cursor:
                                                                                                                  item?.mark_exec_at &&
                                                                                                                  pageData
                                                                                                                      ?.formOrderValues
                                                                                                                      ?.onlyView
                                                                                                                      ? "pointer"
                                                                                                                      : pageData
                                                                                                                            ?.formOrderValues
                                                                                                                            ?.onlyView
                                                                                                                      ? "pointer"
                                                                                                                      : "",
                                                                                                          }}
                                                                                                      />
                                                                                                  </FormGroup>
                                                                                              </Col>
                                                                                              {item &&
                                                                                              item?.mark_exec_at &&
                                                                                              pageData
                                                                                                  ?.formOrderValues
                                                                                                  ?.onlyView ? (
                                                                                                  <UncontrolledTooltip
                                                                                                      style={{
                                                                                                          backgroundColor:
                                                                                                              "#0195d5",
                                                                                                          color:
                                                                                                              "white",
                                                                                                      }}
                                                                                                      placement="right"
                                                                                                      target={
                                                                                                          "execin" +
                                                                                                          index
                                                                                                      }
                                                                                                  >
                                                                                                      Item
                                                                                                      executado
                                                                                                      em:{" "}
                                                                                                      {new Date(
                                                                                                          item?.mark_exec_at
                                                                                                      ).toLocaleDateString(
                                                                                                          "pt-BR"
                                                                                                      )}
                                                                                                  </UncontrolledTooltip>
                                                                                              ) : pageData
                                                                                                    ?.formOrderValues
                                                                                                    ?.onlyView ? (
                                                                                                  <UncontrolledTooltip
                                                                                                      style={{
                                                                                                          backgroundColor:
                                                                                                              "#f1b54f",
                                                                                                          color:
                                                                                                              "white",
                                                                                                      }}
                                                                                                      placement="right"
                                                                                                      target={
                                                                                                          "execin" +
                                                                                                          index
                                                                                                      }
                                                                                                  >
                                                                                                      Item
                                                                                                      não
                                                                                                      executado
                                                                                                  </UncontrolledTooltip>
                                                                                              ) : (
                                                                                                  ""
                                                                                              )}
                                                                                              <Col
                                                                                                  xs="12"
                                                                                                  md="3"
                                                                                                  style={{
                                                                                                      paddingLeft:
                                                                                                          "3px",
                                                                                                      paddingRight:
                                                                                                          "0px",
                                                                                                  }}
                                                                                              >
                                                                                                  <FormGroup
                                                                                                      style={{
                                                                                                          fontSize:
                                                                                                              "0.7rem",
                                                                                                      }}
                                                                                                  >
                                                                                                      <Label className="d-block d-md-none">
                                                                                                          Profissional
                                                                                                          Executor
                                                                                                      </Label>{" "}
                                                                                                      
                                                                                                      <FastField
                                                                                                          id={`items.${index}.exec_professional_id`}
                                                                                                          name={`items[${index}].exec_professional_id`}
                                                                                                          placeholder=""
                                                                                                          cacheOptions
                                                                                                          defaultOptions
                                                                                                          isClearable
                                                                                                          loadOptions={
                                                                                                              debounceOptionsProfessional
                                                                                                          }
                                                                                                          classNamePrefix="select2-selection"
                                                                                                          defaultValue={
                                                                                                              item.exec_professional_id
                                                                                                          }
                                                                                                          component={
                                                                                                              AsyncSelectInput
                                                                                                          }
                                                                                                          isDisabled={
                                                                                                              pageData
                                                                                                                  .formOrderValues
                                                                                                                  .onlyView
                                                                                                          }
                                                                                                          title={
                                                                                                              item
                                                                                                                  ?.exec_professional_id
                                                                                                                  ?.label
                                                                                                          }
                                                                                                      />
                                                                                                  </FormGroup>
                                                                                              </Col>
                                                                                          </>
                                                                                      } */}

                                                                                      <Col
                                                                                          xs="12"
                                                                                          md="1"
                                                                                          style={{
                                                                                              paddingLeft:
                                                                                                  "5px",
                                                                                              paddingRight:
                                                                                                  "0px",
                                                                                          }}
                                                                                          className="d-print-none"
                                                                                      >
                                                                                          <FormGroup>
                                                                                              <Label className="d-block d-md-none">
                                                                                                  Ações
                                                                                              </Label>{" "}
                                                                                              {/* Visível somente em tamanhos de tela xs, sm */}
                                                                                              <UncontrolledDropdown>
                                                                                                  <DropdownToggle
                                                                                                      className="card-drop"
                                                                                                      tag="i"
                                                                                                  >
                                                                                                      <i
                                                                                                          className="bx bx-list-ul ml-2"
                                                                                                          style={{
                                                                                                              fontSize:
                                                                                                                  "30px",
                                                                                                              cursor:
                                                                                                                  "pointer",
                                                                                                          }}
                                                                                                      />
                                                                                                  </DropdownToggle>
                                                                                                  <DropdownMenu
                                                                                                      right
                                                                                                  >
                                                                                                      {item?.id &&
                                                                                                      ![
                                                                                                          SALES_ORDER_STATUS.CANCELED,
                                                                                                      ].includes(
                                                                                                          values?.status
                                                                                                      ) ? (
                                                                                                          <DropdownItem
                                                                                                              className="text-info"
                                                                                                              disabled={
                                                                                                                  item?.item_id
                                                                                                                      ? false
                                                                                                                      : true
                                                                                                              }
                                                                                                              style={{
                                                                                                                  pointerEvents:
                                                                                                                      "all",
                                                                                                                  cursor: item?.item_id
                                                                                                                      ? ""
                                                                                                                      : "no-drop",
                                                                                                              }}
                                                                                                              onClick={() => {
                                                                                                                  let dataModalAppointment = {
                                                                                                                      sales_order_item_id:
                                                                                                                          item?.id,
                                                                                                                      sales_order: id,
                                                                                                                      schedule_by_so: true,
                                                                                                                      service_id:
                                                                                                                          item?.item_id,
                                                                                                                      unit: {
                                                                                                                          id:
                                                                                                                              pageData
                                                                                                                                  .formOrderValues
                                                                                                                                  ?.unit_id
                                                                                                                                  ?.value,
                                                                                                                          name:
                                                                                                                              pageData
                                                                                                                                  .formOrderValues
                                                                                                                                  ?.unit_id
                                                                                                                                  ?.label,
                                                                                                                      },
                                                                                                                      patient_data: {
                                                                                                                          id:
                                                                                                                              values
                                                                                                                                  ?.patient_id
                                                                                                                                  ?.value,
                                                                                                                          name:
                                                                                                                              values
                                                                                                                                  ?.patient_id
                                                                                                                                  ?.label,
                                                                                                                      },
                                                                                                                  }

                                                                                                                  if (
                                                                                                                      item?.appointment
                                                                                                                  ) {
                                                                                                                      dataModalAppointment.appointment =
                                                                                                                          item?.appointment
                                                                                                                      dataModalAppointment.appointment_book =
                                                                                                                          item?.appointment?.appointment_book
                                                                                                                      const {
                                                                                                                          timeObj,
                                                                                                                          dateObj,
                                                                                                                      } = splitDateTime(
                                                                                                                          item
                                                                                                                              ?.appointment
                                                                                                                              ?.start_exec,
                                                                                                                          item
                                                                                                                              ?.appointment
                                                                                                                              ?.end_exec
                                                                                                                      )
                                                                                                                      if (
                                                                                                                          timeObj
                                                                                                                      ) {
                                                                                                                          dataModalAppointment.time = timeObj
                                                                                                                      }
                                                                                                                      if (
                                                                                                                          dateObj
                                                                                                                      ) {
                                                                                                                          dataModalAppointment.date = dateObj
                                                                                                                      }
                                                                                                                  }

                                                                                                                  setModalAppointmentData(
                                                                                                                      {
                                                                                                                          show: true,
                                                                                                                          data: dataModalAppointment,
                                                                                                                      }
                                                                                                                  )
                                                                                                              }}
                                                                                                          >
                                                                                                              <i className="far fa-calendar-check text-primary mr-2" />
                                                                                                              {!item?.appointment
                                                                                                                  ? "Agendar"
                                                                                                                  : "Visualizar agendamento"}
                                                                                                          </DropdownItem>
                                                                                                      ) : null}

                                                                                                      <DropdownItem
                                                                                                          className="text-info"
                                                                                                          disabled={
                                                                                                              item?.item_id
                                                                                                                  ? false
                                                                                                                  : true
                                                                                                          }
                                                                                                          style={{
                                                                                                              pointerEvents:
                                                                                                                  "all",
                                                                                                              cursor: item?.item_id
                                                                                                                  ? ""
                                                                                                                  : "no-drop",
                                                                                                          }}
                                                                                                          onClick={() => {
                                                                                                              if (
                                                                                                                  pageData
                                                                                                                      ?.doc
                                                                                                                      ?.service_type &&
                                                                                                                  pageData
                                                                                                                      ?.doc
                                                                                                                      ?.service_type ==
                                                                                                                      SERVICES_TYPES.DENTAL
                                                                                                              ) {
                                                                                                                  setModalAssessment(
                                                                                                                      {
                                                                                                                          show: true,
                                                                                                                          data: item,
                                                                                                                          details: true,
                                                                                                                      }
                                                                                                                  )
                                                                                                              } else {
                                                                                                                  let showDeleteDiscount = true
                                                                                                                  if (
                                                                                                                      values?.status
                                                                                                                  ) {
                                                                                                                      if (
                                                                                                                          [
                                                                                                                              SALES_ORDER_STATUS.PAID,
                                                                                                                              SALES_ORDER_STATUS.INVOICE_ISSUED,
                                                                                                                              SALES_ORDER_STATUS.CANCELED,
                                                                                                                          ].includes(
                                                                                                                              values.status
                                                                                                                          )
                                                                                                                      ) {
                                                                                                                          showDeleteDiscount = false
                                                                                                                      }
                                                                                                                  }
                                                                                                                  item.showDeleteDiscount = showDeleteDiscount
                                                                                                                  setModalPricingList(
                                                                                                                      {
                                                                                                                          show: true,
                                                                                                                          data: item,
                                                                                                                          user: User,
                                                                                                                          removePricing: newItem => {
                                                                                                                              setFieldValue(
                                                                                                                                  `items.${index}`,
                                                                                                                                  newItem
                                                                                                                              )
                                                                                                                          },
                                                                                                                      }
                                                                                                                  )
                                                                                                              }
                                                                                                          }}
                                                                                                      >
                                                                                                          <i className="fas fa-eye text-primary mr-1" />
                                                                                                          Detalhes
                                                                                                      </DropdownItem>
                                                                                                      {item?.id && (pageSalesDocType === SALES_DOCS_TYPE.SALES_ORDER ||
                                                                                                        (pageSalesDocType === SALES_DOCS_TYPE.SALES_ORDER &&
                                                                                                            pageData?.doc?.service_type &&
                                                                                                            pageData.doc.service_type === SERVICES_TYPES.DENTAL))? (
                                                                                                          <DropdownItem
                                                                                                              className="text-info"
                                                                                                              onClick={() => {
                                                                                                                    const soData = {...pageData?.doc};
                                                                                                                    let itemData = {...item};
                                                                                                                    itemData.so_data = soData;
                                                                                                                  setModalAssessment(
                                                                                                                      {
                                                                                                                          show: true,
                                                                                                                          data: itemData,
                                                                                                                          details: true,
                                                                                                                      }
                                                                                                                  )
                                                                                                                  //   if (
                                                                                                                  //       pageData
                                                                                                                  //           ?.doc
                                                                                                                  //           ?.service_type &&
                                                                                                                  //       pageData
                                                                                                                  //           .doc
                                                                                                                  //           .service_type ===
                                                                                                                  //           SERVICES_TYPES.DENTAL
                                                                                                                  //   ) {
                                                                                                                  //       setModalAssessment(
                                                                                                                  //           {
                                                                                                                  //               show: true,
                                                                                                                  //               data: item,
                                                                                                                  //               details: true,
                                                                                                                  //           }
                                                                                                                  //       )
                                                                                                                  //   } else {
                                                                                                                  //       if (
                                                                                                                  //           hasPermissionExecItem &&
                                                                                                                  //           pageData
                                                                                                                  //               ?.doc
                                                                                                                  //               ?.service_type &&
                                                                                                                  //           pageData
                                                                                                                  //               .doc
                                                                                                                  //               .service_type ===
                                                                                                                  //               SERVICES_TYPES.DENTAL
                                                                                                                  //       ) {
                                                                                                                  //           execItemWithPermission(
                                                                                                                  //               item
                                                                                                                  //           )
                                                                                                                  //       } else {
                                                                                                                  //           confirmExecItem(
                                                                                                                  //               item,
                                                                                                                  //               index
                                                                                                                  //           )
                                                                                                                  //       }
                                                                                                                  //   }
                                                                                                              }}
                                                                                                          >
                                                                                                              <i className="fas fa-exchange-alt text-info mr-2" />
                                                                                                              Detalhes
                                                                                                              de
                                                                                                              Execução
                                                                                                          </DropdownItem>
                                                                                                      ) : null}
                                                                                                      <DropdownItem
                                                                                                          style={{
                                                                                                              pointerEvents:
                                                                                                                  "all",
                                                                                                              cursor: pageData
                                                                                                                  .formOrderValues
                                                                                                                  .onlyView
                                                                                                                  ? "no-drop"
                                                                                                                  : "",
                                                                                                          }}
                                                                                                          className="text-danger"
                                                                                                          disabled={
                                                                                                              pageData
                                                                                                                  .formOrderValues
                                                                                                                  .onlyView
                                                                                                          }
                                                                                                          onClick={() =>
                                                                                                              arrayHelpers.remove(
                                                                                                                  index
                                                                                                              )
                                                                                                          }
                                                                                                      >
                                                                                                          <i className="fas fa-trash-alt text-danger mr-2" />
                                                                                                          Remover
                                                                                                      </DropdownItem>

                                                                                                      {![
                                                                                                          SALES_ORDER_STATUS.PAID,
                                                                                                          SALES_ORDER_STATUS.INVOICE_ISSUED,
                                                                                                          SALES_ORDER_STATUS.CANCELED,
                                                                                                      ].includes(
                                                                                                          values.status
                                                                                                      ) &&
                                                                                                      item?.item_id &&
                                                                                                      !item
                                                                                                          ?.item_id
                                                                                                          ?.showInputPriceDisabled ? (
                                                                                                          <DropdownItem
                                                                                                              className="text-info"
                                                                                                              onClick={() => {
                                                                                                                  setModalPricingItem(
                                                                                                                      {
                                                                                                                          show: true,
                                                                                                                          data: item,
                                                                                                                          applyPricingForm: price => {
                                                                                                                              setFieldValue(
                                                                                                                                  `items.${index}.price`,
                                                                                                                                  price
                                                                                                                              )
                                                                                                                          },
                                                                                                                          applyRulePricing: data => {
                                                                                                                              let dataPricing = [
                                                                                                                                  ...item?.pricing,
                                                                                                                              ]
                                                                                                                              dataPricing.push(
                                                                                                                                  data
                                                                                                                              )
                                                                                                                              setFieldValue(
                                                                                                                                  `items.${index}.pricing`,
                                                                                                                                  dataPricing
                                                                                                                              )
                                                                                                                          },
                                                                                                                      }
                                                                                                                  )
                                                                                                              }}
                                                                                                          >
                                                                                                              {/* {console.log(item)} */}
                                                                                                              <i className="fas fa-exchange-alt text-info mr-2" />
                                                                                                              Aplicar
                                                                                                              desconto
                                                                                                          </DropdownItem>
                                                                                                      ) : null}
                                                                                                      {/* {!item?.mark_exec_at &&
                                                                                                      pageSalesDocType ==
                                                                                                          SALES_DOCS_TYPE.SALES_ORDER &&
                                                                                                      pageData
                                                                                                          .formOrderValues
                                                                                                          .onlyView &&
                                                                                                      [
                                                                                                          SALES_ORDER_STATUS.PAID,
                                                                                                          SALES_ORDER_STATUS.INVOICE_ISSUED,
                                                                                                      ].includes(
                                                                                                          values.status
                                                                                                      ) &&
                                                                                                      pageData
                                                                                                          ?.doc
                                                                                                          ?.service_type !==
                                                                                                          SERVICES_TYPES.DENTAL ? (
                                                                                                          <DropdownItem
                                                                                                              className="text-info"
                                                                                                              onClick={() => {
                                                                                                                  setModalDataExecItem(
                                                                                                                      {
                                                                                                                          show: true,
                                                                                                                          data: item,
                                                                                                                      }
                                                                                                                  )
                                                                                                              }}
                                                                                                          >
                                                                                                              <i className="fas fa-exchange-alt text-info mr-2" />
                                                                                                              Alterar
                                                                                                              execução
                                                                                                          </DropdownItem>
                                                                                                      ) : (
                                                                                                          ""
                                                                                                      )} */}
                                                                                                  </DropdownMenu>
                                                                                              </UncontrolledDropdown>
                                                                                          </FormGroup>
                                                                                      </Col>
                                                                                  </Row>
                                                                              )
                                                                          )
                                                                        : null}

                                                                    <Button
                                                                        color="primary"
                                                                        className="d-print-none"
                                                                        onClick={() => {
                                                                            if (
                                                                                pageData
                                                                                    ?.doc
                                                                                    ?.service_type &&
                                                                                pageData
                                                                                    ?.doc
                                                                                    ?.service_type ==
                                                                                    SERVICES_TYPES.DENTAL
                                                                            ) {
                                                                                setModalAssessment(
                                                                                    {
                                                                                        show: true,
                                                                                        data: values,
                                                                                    }
                                                                                )
                                                                            } else {
                                                                                arrayHelpers.push(
                                                                                    defaultItemValues
                                                                                )
                                                                            }
                                                                        }}
                                                                        disabled={
                                                                            pageData
                                                                                .formOrderValues
                                                                                .onlyView
                                                                        }
                                                                    >
                                                                        <i className="fas fa-plus"></i>
                                                                    </Button>
                                                                </div>
                                                            )}
                                                        />
                                                    </Col>
                                                </Row>

                                                {/* fim linha */}
                                            </CardBody>
                                        </Card>

                                        <Row>
                                            <Col lg="6">
                                                <Card>
                                                    <CardBody>
                                                        <CardTitle>
                                                            {" "}
                                                            Pagamento
                                                        </CardTitle>
                                                        <CardSubtitle className="mb-2"></CardSubtitle>
                                                        <br />

                                                        <Row>
                                                            <Col sm="6">
                                                                <Label>
                                                                    Forma de
                                                                    Pagamento
                                                                </Label>
                                                                <Field
                                                                    name="pay_form_id"
                                                                    placeholder=""
                                                                    classNamePrefix="select2-selection"
                                                                    options={
                                                                        pay_formsOptions
                                                                    }
                                                                    isMulti={
                                                                        false
                                                                    }
                                                                    component={
                                                                        ReactSelectInput
                                                                    }
                                                                    isDisabled={
                                                                        pageData
                                                                            .formOrderValues
                                                                            .onlyView
                                                                    }
                                                                />
                                                            </Col>
                                                            <Col sm="6">
                                                                <Label>
                                                                    Condição de
                                                                    Pagamento
                                                                </Label>
                                                                <Field
                                                                    name="pay_terms_id"
                                                                    placeholder=""
                                                                    classNamePrefix="select2-selection"
                                                                    options={
                                                                        pay_termsOptions
                                                                    }
                                                                    isMulti={
                                                                        false
                                                                    }
                                                                    component={
                                                                        ReactSelectInput
                                                                    }
                                                                    isDisabled={
                                                                        pageData
                                                                            .formOrderValues
                                                                            .onlyView
                                                                    }
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </CardBody>
                                                </Card>
                                            </Col>

                                            <Col lg="6">
                                                <Card>
                                                    <CardBody>
                                                        <CardTitle>
                                                            {" "}
                                                            Resumo
                                                        </CardTitle>
                                                        {sumDiscounts(
                                                            values?.items
                                                        )}

                                                        <CardSubtitle className="mb-2"></CardSubtitle>
                                                        <br />

                                                        <Row>
                                                            <Col sm="6">
                                                                <Label>
                                                                    Valor Total
                                                                    Líquido
                                                                </Label>
                                                                <Field
                                                                    name={
                                                                        "total_value"
                                                                    }
                                                                    displayType={
                                                                        "input"
                                                                    }
                                                                    thousandSeparator={
                                                                        "."
                                                                    }
                                                                    decimalSeparator={
                                                                        ","
                                                                    }
                                                                    decimalScale={
                                                                        2
                                                                    }
                                                                    fixedDecimalScale={
                                                                        true
                                                                    }
                                                                    allowEmptyFormatting={
                                                                        true
                                                                    }
                                                                    prefix={
                                                                        "R$ "
                                                                    }
                                                                    style={{
                                                                        backgroundColor:
                                                                            "#f2f2f2",
                                                                    }}
                                                                    className="form-control"
                                                                    formValues={
                                                                        values
                                                                    }
                                                                    component={
                                                                        TotalValueInput
                                                                    }
                                                                    disabled
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <div align="end">
                                    <Button
                                        className="btn btn-info ml-2 d-print-none"
                                        type="button"
                                        onClick={() => {
                                            if (
                                                pageData?.doc?.service_type !=
                                                SERVICES_TYPES.DENTAL
                                            ) {
                                                handlePrint()
                                            } else {
                                                window.open(
                                                    `/relatorios/dados-odontologicos?entity_type=${pageSalesDocType}&entity_id=${pageData?.doc?.id}&assessment_id=${pageData?.doc?.assessment_id}`,
                                                    "_blank"
                                                )
                                            }
                                        }}
                                    >
                                        {" "}
                                        Imprimir{" "}
                                    </Button>
                                    <Button
                                        // to={
                                        //     pageSalesDocType ==
                                        //     SALES_DOCS_TYPE.SALES_QUOTE
                                        //         ? "/orcamentos"
                                        //         : "/pedidos"
                                        // }
                                        onClick={() => {
                                            if (
                                                stateLocation?.state
                                                    ?.previousPath
                                            ) {
                                                history.goBack()
                                            } else {
                                                pageSalesDocType ==
                                                SALES_DOCS_TYPE.SALES_QUOTE
                                                    ? history.push(
                                                          "/orcamentos"
                                                      )
                                                    : history.push("/pedidos")
                                            }
                                        }}
                                        className="btn btn-danger ml-2 d-print-none"
                                        disabled={isSubmitting}
                                    >
                                        Sair
                                    </Button>
                                    {!pageData.formOrderValues.onlyView &&
                                    values.status !=
                                        SALES_QUOTE_STATUS.IN_NEGOTIATION ? (
                                        <Button
                                            onClick={() => {
                                                setFieldValue(
                                                    "generate_sales_order",
                                                    false
                                                )
                                            }}
                                            color="primary"
                                            type="submit"
                                            className="ml-2 d-print-none"
                                            disabled={isSubmitting || !dirty}
                                        >
                                            Salvar
                                        </Button>
                                    ) : null}

                                    {!pageData.formOrderValues.onlyView &&
                                    id &&
                                    pageSalesDocType ==
                                        SALES_DOCS_TYPE.SALES_QUOTE &&
                                    values.items?.length > 0 &&
                                    pageData?.doc?.service_type !=
                                        SERVICES_TYPES.DENTAL ? (
                                        <Button
                                            style={{
                                                backgroundColor: "#0195d5",
                                                borderColor: "#0195d5",
                                            }}
                                            type="button"
                                            className="ml-2 d-print-none"
                                            disabled={isSubmitting}
                                            onClick={() => {
                                                setFieldValue(
                                                    "generate_sales_order",
                                                    true
                                                )
                                                try {
                                                    submitForm()
                                                } catch (e) {}
                                            }}
                                        >
                                            Gerar Pedido
                                        </Button>
                                    ) : null}
                                </div>
                                <br />
                                <ModalAssessment
                                    type={pageSalesDocType}
                                    show={modalAssessment?.show}
                                    data={modalAssessment?.data}
                                    details={modalAssessment.details}
                                    doc_service_type={
                                        pageData?.doc?.service_type ?? 1
                                    }
                                    loadDataEditPage={loadDataEditPage}
                                    onClose={async props => {
                                        if (props?.refreshOptions) {
                                            let response = await loadDataEditPage()

                                            let salesOrderItemId =
                                                props?.refreshOptions
                                                    ?.sales_order_item_id

                                            let item = response?.formOrderValues?.items?.find(
                                                e => e?.id == salesOrderItemId
                                            )

                                            item.so_data = {...pageData?.doc};

                                            setModalAssessment({
                                                show: true,
                                                data: item,
                                                details: true,
                                            })
                                        } else {
                                            setModalAssessment({
                                                show: false,
                                                data: null,
                                                details: false,
                                            })
                                        }
                                    }}
                                    setFieldValue={setFieldValue}
                                />
                            </Form>
                        )}
                    </Formik>
                </Container>
                <ModalPayment
                    show={modalPaymentData.show}
                    data={modalPaymentData.data}
                    subscriptionData={
                        patientHasSubscription.data?.expand_vitta_cards
                    }
                    onClose={operations => {
                        setModalPaymentData({ show: false, data: null })
                        if (
                            operations &&
                            (operations.create || operations.cancel)
                        )
                            setIdStatus(new Date().getTime()) //Renderizar o form
                    }}
                ></ModalPayment>
                <HandleDataExecItem
                    show={modalDataExecItem.show}
                    data={modalDataExecItem.data}
                    onClose={render => {
                        if (render) {
                            loadDataEditPage()
                        }
                        setModalDataExecItem({ show: false, data: null })
                    }}
                />
                <HandlePricingItem
                    show={modalPricingItem.show}
                    data={modalPricingItem.data}
                    applyPricingForm={modalPricingItem.applyPricingForm}
                    applyRulePricing={modalPricingItem.applyRulePricing}
                    onClose={render => {
                        setModalPricingItem({ show: false, data: null })
                    }}
                />
                <HandlePricingAllItems
                    show={modalPricingAllItems.show}
                    data={modalPricingAllItems.data}
                    refreshItemsForm={modalPricingAllItems.refreshItemsForm}
                    onClose={render => {
                        setModalPricingAllItems({ show: false, data: null })
                    }}
                />
                <PricingList
                    show={modalPricingList.show}
                    data={modalPricingList.data}
                    user={modalPricingList.user}
                    removePricing={modalPricingList?.removePricing}
                    onClose={newData => {
                        setModalPricingList({ show: false, data: null })
                    }}
                />
            </div>
            <ModalExamRequests
                show={controlModalExamRequest?.show}
                data={controlModalExamRequest?.data}
                renderRecord={renderRecordExamModal}
                onClose={() => {
                    setControlModalExamRequest({
                        show: false,
                        data: null,
                    })
                }}
            />
            <ModalBenefits
                show={modalBenefits?.show}
                data={modalBenefits?.data}
                onClose={() => {
                    setModalBenefits({
                        show: false,
                        data: null,
                    })
                }}
            />
            <ModalAppointment
                show={modalAppointmentData.show}
                data={modalAppointmentData.data}
                onClose={() => {
                    setModalAppointmentData({ show: false, data: null })
                    loadDataEditPage()
                }}
            ></ModalAppointment>
            <ModalHistoricAppointments
                show={modalHistoricAppointments.show}
                sales_order_id={id}
                onClose={() => {
                    setModalHistoricAppointments({ show: false })
                }}
            />
        </React.Fragment>
    )
}

export default RegistrationSalesOrders
