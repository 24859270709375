import React from "react"
import { Row, Col, Modal, Label, Input } from "reactstrap"
import { Formik, Form, FastField } from "formik"
import { useDispatch } from "react-redux"
import { showSweetAlertDialog, showToast } from "store/layout/actions"
import { changeExecItem } from "services/vitta-core/sales-order"
import AsyncSelect from "react-select/async"
import debounce from "debounce-promise"
import { search as professionalServiceSearch } from "services/vitta-core/professional"

const loadProfessionalsInputOptions = async inputValue => {
    return new Promise(async (resolve, reject) => {
        try {
            if (!inputValue) return resolve([])
            const statusActive = 1
            let response = await professionalServiceSearch(
                { term: inputValue, status: statusActive },
                1,
                20
            )

            let items_values = []

            response.data.forEach(element => {
                items_values.push({
                    label: element.name,
                    value: element.id,
                })
            })

            return resolve(items_values)
        } catch (e) {
            console.error(e)
            return reject([])
        }
    })
}

const debounceOptionsProfessional = debounce(loadProfessionalsInputOptions, 600)
const AsyncSelectInput = ({
    field, // { name, value, onChange, onBlur }
    form,
    form: { setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    ...props
}) => (
    <AsyncSelect
        {...field}
        {...props}
        onChange={option => {
            setFieldValue(field.name, option)
        }}
        noOptionsMessage={() => "Sem Resultados. Digite para Buscar"}
        ref={props.RefPatient}
    />
)

const DateInput = ({
    field, // { name, value, onChange, onBlur }
    ...props
}) => <Input type="date" {...field} {...props} />

const HandleDataExecItem = ({ show, data, onClose }) => {
    const dispatch = useDispatch()

    const defaultValues = {
        date_change: "",
        exec_professional: null,
    }

    const confirmChangeData = data_form => {
        dispatch(
            showSweetAlertDialog({
                title: "Atenção ",
                text: "Tem certeza que deseja excluir este registro?",
                type: "warning",
                onConfirmAction: () => {
                    onSubmit(data_form)
                },
                showCancel: true,
                showConfirm: true,
                cancelBtnText: "Não",
                confirmBtnText: "Sim",
            })
        )
    }

    const onSubmit = async data_form => {
        try {
            if (!data_form?.date_change) {
                dispatch(
                    showSweetAlertDialog({
                        title: "Preencha o campo de data!",
                        type: "error",
                    })
                )
                return
            }
          
            let dataSend = {
                date_change: data_form?.date_change,
                exec_professional_id: data_form?.exec_professional?.value
            }

        
            await changeExecItem(data?.id, dataSend);

            dispatch(
                showToast({
                    title: "Sucesso",
                    text: "Registro salvo com Sucesso!",
                    type: "success",
                })
            )
            onClose(true)
        } catch (e) {
            console.log(e)
            if (e.response.data.error_code === "executed_item") {
                dispatch(
                    showSweetAlertDialog({
                        title: "Esse item já foi executado!",
                        type: "error",
                        showConfirm: true,
                    })
                )
                return
            }
            dispatch(
                showSweetAlertDialog({
                    title: "Ops... ",
                    text:
                        "Houve um erro ao processar sua solicitação, tente novamente mais tarde...",
                    type: "error",
                    showConfirm: true,
                })
            )
        }
    }

    return (
        <Modal
            isOpen={show}
            centered={true}
            // toggle={onCloseModal}
        >
            <Formik
                initialValues={defaultValues}
                enableReinitialize={true}
                onSubmit={confirmChangeData}
            >
                {({ values, isSubmitting }) => (
                    <Form>
                        <div className="modal-header">
                            <h5 className="modal-title mt-0" id="myModalLabel">
                                Alterar execução - Item #{data?.id}
                            </h5>
                            <button
                                type="button"
                                onClick={() => {
                                    onClose()
                                }}
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <Row>
                                <Col md="12">
                                    <Label>Nova data de execução</Label>
                                    <FastField
                                        name={`date_change`}
                                        defaultValue={values.date_change}
                                        component={DateInput}
                                    />
                                </Col>
                                <Col md="12" className="mt-2">
                                    <Label>Novo profissional de execução</Label>
                                    <FastField
                                        name={`exec_professional`}
                                        placeholder="Digite para buscar"
                                        cacheOptions
                                        defaultOptions
                                        isClearable
                                        defaultValue={values.exec_professional}
                                        loadOptions={
                                            debounceOptionsProfessional
                                        }
                                        classNamePrefix="select2-selection"
                                        component={AsyncSelectInput}
                                    />
                                </Col>
                            </Row>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                onClick={() => {
                                    onClose()
                                }}
                                className="btn-sm btn-secondary waves-effect"
                                data-dismiss="modal"
                            >
                                Fechar
                            </button>
                            <button
                                type="submit"
                                className="btn-sm btn-primary waves-effect waves-light"
                            >
                                Confirmar
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        </Modal>
    )
}

export default HandleDataExecItem
