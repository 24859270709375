import React, { useEffect, useState, useRef, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, useHistory } from "react-router-dom"
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import debounce from "debounce-promise"
import { search as searchExecutionControls } from "services/vitta-core/execution-controls"
import {
    Button,
    Card,
    CardBody,
    Col,
    Container,
    Row,
    Input,
    Label,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import DocsListColumns from "./ListColumns"

import { showSweetAlertDialog, showToast } from "store/layout/actions"
import AsyncSelect from "react-select/async"
import Select from "react-select"

import { search as professionalServiceSearch } from "services/vitta-core/professional"
import {
    previewInvoices,
    genInvoices,
} from "services/vitta-core/professional-transfer-invoices"
import { processExecutions } from "services/vitta-core/execution-controls"
const today = new Date().toLocaleDateString("pt-BR")
const todayOnlyDate =
    today.substr(6, 4) + "-" + today.substr(3, 2) + "-" + today.substr(0, 2)

const ExecutionControl = () => {
    const Scope = useSelector(state => state.scope)
    const User = useSelector(state => state.auth.access.user)

    const [salesInvoiceDocsList, setDataList] = useState({
        custom: true,
        data: [],
    })

    const [isCheck, setIsCheck] = useState([])

    const [canGen, setCanGen] = useState(false)

    const handleOnSelect = (row, isSelect) => {
        if (isSelect) {
            setIsCheck([...isCheck, row.id])
        } else {
            setIsCheck(isCheck.filter(item => item !== row.id))
        }
        return true // return true or dont return to approve current select action
    }

    const handleOnSelectAll = (isSelect, rows) => {
        if (isSelect) {
            setIsCheck(rows.map(i => i.id))
        } else {
            setIsCheck([])
        }
    }

    const selectRow = {
        mode: "checkbox",
        clickToSelect: true,
        selected: isCheck,
        onSelect: handleOnSelect,
        onSelectAll: handleOnSelectAll,
    }

    const inputRefProfessional = useRef()
    const inputRefUnit = useRef()
    const inputRefStatus = useRef()
    const inputRefDocExecIn = useRef()
    const inputRefDocPed = useRef()
    const inputRefMarkExecPed = useRef()
    const dispatch = useDispatch()

    const defaultPageControl = {
        searching: false,
    }
    const [pageControl, setPageControl] = useState(defaultPageControl)

    const search = async filter => {
        if (pageControl.searching) return

        try {
            setPageControl({ ...pageControl, ...{ searching: true } })

            setDataList({
                custom: true,
                data: [],
            })

            let data = await searchExecutionControls(filter)

            //console.log("data",data)
            setDataList({
                custom: true,
                data: data,
            })
        } catch (e) {
            console.log("error", e)
        }

        setPageControl({ ...pageControl, ...{ searching: false } })
    }

    const onTableChange = (a, b, c) => {}

    const submitTransfer = async () => {
        if (isCheck.length == 0) {
            return dispatch(
                showSweetAlertDialog({
                    title: "Ops... ",
                    text: "Selecione as execuções para realizar o repasse.",
                    type: "error",
                    showConfirm: true,
                })
            )
        }

        dispatch(
            showSweetAlertDialog({
                title: "Atenção ",
                text: "Tem certeza que deseja processar os itens selecionados?",
                type: "warning",
                onConfirmAction: () => {
                    confirmSubmitTransfer()
                },
                showCancel: true,
                showConfirm: true,
                cancelBtnText: "Não",
                confirmBtnText: "Sim",
            })
        )
    }

    const confirmSubmitTransfer = async () => {
        try {
          
            await processExecutions(isCheck)

            dispatch(
                showToast({
                    title: "Sucesso!",
                    text: "Registros processados.",
                    type: "success",
                })
            )

            searchPreviewData()
        } catch (e) {
            console.log(e)
            let title = "Ops... "
            let textError =
                "Houve um erro ao processar sua solicitação, tente novamente mais tarde..."

            if (e?.response?.status == 403) {
                textError =
                    "Você não possui permissões para realizar essa operação. Solicite ao seu Gestor."
            } else if (e?.response?.data) {
                switch (e.response.data.error_code) {
                    case "prof_transfer_invoice_exists":
                        textError =
                            "Esta nota de Repasse já foi gerada. Entre em contato com o suporte Técnico."
                        break
                    case "date_transfer_invalid":
                        title = "Data inválida !"
                        textError =
                            "Para gerar uma nota de repasse, é necessário que a memda possua data de execução referente à data atual ou uma data de execução inferior a data atual."
                }
            }

            dispatch(
                showSweetAlertDialog({
                    title: title,
                    text: textError,
                    type: "error",
                    showConfirm: true,
                })
            )
        }
    }

    const searchPreviewData = (page = 1) => {
        let searchObj = {}
        let exec_professional_id =
            inputRefProfessional?.current?.select?.state?.value?.value
        let exec_in = inputRefDocExecIn.current.value
        let mark_exec_at = inputRefMarkExecPed.current.value
        let unit_id = inputRefUnit.current?.state?.value?.value
        let status = inputRefStatus.current?.state?.value?.value
        setCanGen(true)
        setIsCheck([])

        if (exec_professional_id)
            searchObj.exec_professional_id = exec_professional_id
        if (exec_in) searchObj.exec_in = exec_in
        if (unit_id) searchObj.unit_id = unit_id
        if (mark_exec_at) searchObj.mark_exec_at = mark_exec_at
        if (status) searchObj.status = status

        // if (!exec_professional_id) {
        //     return dispatch(
        //         showSweetAlertDialog({
        //             title: "Ops... ",
        //             text: "Informe o profissional no filtro.",
        //             type: "error",
        //             showConfirm: true,
        //         })
        //     )
        // }

        if (!exec_in && !doc_created) {
            return dispatch(
                showSweetAlertDialog({
                    title: "Ops... ",
                    text:
                        "Informe data de execução ou data de criação do pedido de venda!",
                    type: "error",
                    showConfirm: true,
                })
            )
        }

        if (!unit_id) {
            return dispatch(
                showSweetAlertDialog({
                    title: "Ops... ",
                    text: "Informe a unidade no filtro.",
                    type: "error",
                    showConfirm: true,
                })
            )
        }

        search(searchObj, page)
    }

    const loadProfessionalInputOptions = async inputValue => {
        return new Promise(async (resolve, reject) => {
            try {
                const statusActive = 1
                let response = await professionalServiceSearch(
                    { term: inputValue, status: statusActive },
                    1,
                    20
                )

                let list_values = []

                response.data.forEach(element => {
                    list_values.push({
                        label: `${element.id} - ` + element.name,
                        value: element.id,
                    })
                })

                return resolve(list_values)
            } catch (e) {
                console.error(e)
                return reject([])
            }
        })
    }

    const debounceOptionsProfessional = debounce(
        loadProfessionalInputOptions,
        600
    )

    const unitsOptions = useMemo(() => {
        if (!User?.units) return []

        let options_list = []
        User.units.forEach(element => {
            options_list.push({
                label: `#${element.id} ` + element.name?.substr(0, 40),
                value: element.id,
            })
        })
        return options_list
    }, [User.units])

    const renderNoDataIndication = () => {
        if (pageControl.searching)
            return (
                <Row align="center" className="mt-5 mb-5">
                    <Col md="12" className="mt-5 mb-5">
                        <div
                            className="spinner-border text-primary"
                            role="status"
                        >
                            <span className="sr-only">Loading...</span>
                        </div>
                    </Col>
                </Row>
            )
        else {
            return (
                <Row className="mt-3 mb-3">
                    <Col md="12">
                        Não há registros que correspondam com a sua busca.
                    </Col>
                </Row>
            )
        }
    }

    const userIsProfessionalType = useMemo(() => {
        let found = false

        let accessRoles = process.env.REACT_APP_PROF_ACCESS_RULES_IDS
        let accessRolesIds = accessRoles?.split(",")

        if (accessRolesIds?.length > 0) {
            for (let i = 0; i < accessRolesIds?.length; i++) {
                let accessRoleId = accessRolesIds[i]
                let hasRole = User?.access_roles?.find(
                    e => e?.id == accessRoleId
                )
                if (hasRole) {
                    found = true
                    break
                }
            }
        }

        return found
    }, [User])

    useEffect(() => {
        searchPreviewData()
    }, [])
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs
                        title="Execuções"
                        breadcrumbItem="Controle de Execução"
                    />
                    <Row>
                        <Col xs="12">
                            <Card>
                                <CardBody>
                                    <ToolkitProvider
                                        keyField="id"
                                        data={salesInvoiceDocsList.data || []}
                                        columns={DocsListColumns()}
                                        bootstrap4
                                    >
                                        {toolkitProps => (
                                            <React.Fragment>
                                                <Row className="mb-4">
                                                    <Col
                                                        xl="2"
                                                        md="6"
                                                        sm="6"
                                                        xs="12"
                                                    >
                                                        <Label>Unidade</Label>
                                                        <Select
                                                            name="unit_id"
                                                            defaultValue={
                                                                Scope.unit
                                                                    ? {
                                                                          label:
                                                                              `#${Scope.unit.id} ` +
                                                                              Scope.unit.name?.substr(
                                                                                  0,
                                                                                  40
                                                                              ),
                                                                          value:
                                                                              Scope
                                                                                  .unit
                                                                                  .id,
                                                                      }
                                                                    : null
                                                            }
                                                            ref={inputRefUnit}
                                                            options={
                                                                unitsOptions
                                                            }
                                                            onChange={() => {
                                                                setCanGen(false)
                                                            }}
                                                        />
                                                    </Col>
                                                    {!userIsProfessionalType ? (
                                                        <Col
                                                            xl="2"
                                                            md="6"
                                                            sm="6"
                                                            xs="12"
                                                        >
                                                            <Label>
                                                                Profissional
                                                            </Label>
                                                            <AsyncSelect
                                                                name="patient_id"
                                                                placeholder="Selecione"
                                                                ref={
                                                                    inputRefProfessional
                                                                }
                                                                cacheOptions
                                                                defaultOptions
                                                                isClearable
                                                                loadOptions={
                                                                    debounceOptionsProfessional
                                                                }
                                                                onChange={() => {
                                                                    setCanGen(
                                                                        false
                                                                    )
                                                                }}
                                                                noOptionmdessage={() =>
                                                                    "Sem Resultados. Digite para Buscar"
                                                                }
                                                            />
                                                        </Col>
                                                    ) : null}
                                                    <Col
                                                        xl="2"
                                                        md="6"
                                                        sm="6"
                                                        xs="12"
                                                    >
                                                        <Label>Status</Label>
                                                        <Select
                                                            name="status"
                                                            ref={inputRefStatus}
                                                            defaultValue={{
                                                                label:
                                                                    "Aguardando Execução",
                                                                value: 2,
                                                            }}
                                                            options={[
                                                                {
                                                                    label:
                                                                        "Executado",
                                                                    value: 1,
                                                                },
                                                                {
                                                                    label:
                                                                        "Aguardando Execução",
                                                                    value: 2,
                                                                },
                                                            ]}
                                                            onChange={() => {
                                                                setCanGen(false)
                                                            }}
                                                        />
                                                    </Col>
                                                    <Col
                                                        xl="2"
                                                        md="6"
                                                        sm="6"
                                                        xs="12"
                                                    >
                                                        <Label>
                                                            Data Previsão Execução
                                                        </Label>
                                                        <Input
                                                            type="date"
                                                            innerRef={
                                                                inputRefDocExecIn
                                                            }
                                                            defaultValue={
                                                                todayOnlyDate
                                                            }
                                                            onChange={() => {
                                                                setCanGen(false)
                                                            }}
                                                        ></Input>
                                                    </Col>
                                                    <Col
                                                        xl="2"
                                                        md="6"
                                                        sm="6"
                                                        xs="12"
                                                    >
                                                        <Label>
                                                            Data Execução
                                                        </Label>
                                                        <Input
                                                            type="date"
                                                            innerRef={
                                                                inputRefMarkExecPed
                                                            }
                                                            onChange={() => {
                                                                setCanGen(false)
                                                            }}
                                                        ></Input>
                                                    </Col>
                                                    <Col
                                                        xl="2"
                                                        md="6"
                                                        sm="6"
                                                        xs="12"
                                                    >
                                                        <div className="">
                                                            <Link
                                                                className="btn btn-primary mt-4"
                                                                onClick={() =>
                                                                    searchPreviewData()
                                                                }
                                                                style={{
                                                                    width:
                                                                        "100%",
                                                                    position:
                                                                        "relative",
                                                                    top: "5px",
                                                                }}
                                                            >
                                                                Buscar
                                                            </Link>
                                                        </div>
                                                    </Col>
                                                    <Col
                                                        xl="2"
                                                        md="6"
                                                        sm="6"
                                                        xs="12"
                                                    >
                                                        {/* {canGen &&
                                                        isCheck.length > 0 ? (
                                                            <div className=" ">
                                                                <Link
                                                                    className={
                                                                        inputRefStatus
                                                                            .current
                                                                            ?.state
                                                                            ?.value
                                                                            ?.value ==
                                                                        2
                                                                            ? "btn btn-info mt-4"
                                                                            : "btn btn-danger mt-4"
                                                                    }
                                                                    onClick={
                                                                        submitTransfer
                                                                    }
                                                                >
                                                                    <i className="fas fa-microchip"></i>{" "}
                                                                    {inputRefStatus
                                                                        .current
                                                                        ?.state
                                                                        ?.value
                                                                        ?.value ==
                                                                    2
                                                                        ? "Executar"
                                                                        : "Remover Execução"}
                                                                </Link>
                                                            </div>
                                                        ) : null} */}
                                                        {canGen &&
                                                        isCheck.length > 0 &&
                                                        inputRefStatus.current
                                                            ?.state?.value
                                                            ?.value == 2 ? (
                                                            <div className=" ">
                                                                <Link
                                                                    className={
                                                                        inputRefStatus
                                                                            .current
                                                                            ?.state
                                                                            ?.value
                                                                            ?.value ==
                                                                        2
                                                                            ? "btn btn-info mt-4"
                                                                            : "btn btn-danger mt-4"
                                                                    }
                                                                    onClick={
                                                                        submitTransfer
                                                                    }
                                                                >
                                                                    <i className="fas fa-microchip"></i>{" "}
                                                                    {inputRefStatus
                                                                        .current
                                                                        ?.state
                                                                        ?.value
                                                                        ?.value ==
                                                                    2
                                                                        ? "Executar"
                                                                        : "Remover Execução"}
                                                                </Link>
                                                            </div>
                                                        ) : inputRefStatus
                                                              .current?.state
                                                              ?.value?.value !=
                                                          2 ? (
                                                            <small
                                                                style={{
                                                                    position:
                                                                        "relative",
                                                                    top: "10px",
                                                                }}
                                                            >
                                                                <strong className="text-danger">
                                                                    <i className="fas fa-exclamation-circle"></i>{" "}
                                                                    Estes
                                                                    serviços já
                                                                    foram
                                                                    executados
                                                                </strong>
                                                            </small>
                                                        ) : null}
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xl="12">
                                                        <div className="table-responsive">
                                                            <BootstrapTable
                                                                responsive
                                                                remote
                                                                bordered={false}
                                                                striped={false}
                                                                classes={
                                                                    "table table-centered table-nowrap"
                                                                }
                                                                headerWrapperClasses={
                                                                    "thead-light"
                                                                }
                                                                {...toolkitProps.baseProps}
                                                                selectRow={
                                                                    selectRow
                                                                }
                                                                onTableChange={
                                                                    onTableChange
                                                                }
                                                                noDataIndication={
                                                                    renderNoDataIndication
                                                                }
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </React.Fragment>
                                        )}
                                    </ToolkitProvider>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default ExecutionControl
