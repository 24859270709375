import request from "./config/request"

const create = async professional_data => {
    return request({
        method: "POST",
        url:
            process.env.REACT_APP_VITTA_SERVICES_HOST +
            `/vittanet/professionals`,
        data: professional_data,
        vtAuth: true,
    })
}

const update = async (id, professional_data) => {
    return request({
        method: "POST",
        url:
            process.env.REACT_APP_VITTA_SERVICES_HOST +
            `/vittanet/professionals/${id}`,
        data: professional_data,
        vtAuth: true,
    })
}

const syncTaxdocsData = async id => {
    return request({
        method: "POST",
        url:
            process.env.REACT_APP_VITTA_SERVICES_HOST +
            `/vittanet/professionals/sync-taxdocs/${id}`,
        vtAuth: true,
    })
}

// const updateDataCertificate = async (id, data) => {
//     return request({
//         method: "POST",
//         url:  process.env.REACT_APP_VITTA_SERVICES_HOST + `/vittanet/professionals-certificate/${id}`,
//         data: data,
//         vtAuth: true
//     })
// }
const loadDataAddform = async () => {
    return request({
        method: "GET",
        url:
            process.env.REACT_APP_VITTA_SERVICES_HOST +
            `/vittanet/professionals/add`,
        vtAuth: true,
    })
}

const loadDataEditform = async id => {
    return request({
        method: "GET",
        url:
            process.env.REACT_APP_VITTA_SERVICES_HOST +
            `/vittanet/professionals/${id}/edit`,
        vtAuth: true,
    })
}

const search = (filter, page = 1, size = 10) => {
    let query = "?page=" + page + "&size=" + size
    if (filter.term) query += "&term=" + filter.term
    if (filter.status) query += "&status=" + filter.status
    return request({
        method: "GET",
        url:
            process.env.REACT_APP_VITTA_SERVICES_HOST +
            `/vittanet/professionals` +
            query,
        vtAuth: true,
    })
}

const searchReportProfessional = (filter) =>{

    let query = ""
    if(filter.status) query +="?status=" + filter.status

    return request({
        method: "GET",
        responseType: "arraybuffer", // important
        url:  process.env.REACT_APP_VITTA_SERVICES_HOST +
        `/vittanet/professionals/report` +
        query,
        vtAuth: true,
        returnFullResponse: true,
    })
}

const uploadImageProfessional = async (id, dataProfessional) => {
    return request({
        method: "POST",
        url:
            process.env.REACT_APP_VITTA_SERVICES_HOST +
            `/vittanet/upload-image-professional/${id}`,
        data: dataProfessional,
        vtAuth: true,
        headers: {
            "Content-Type": "multipart/form-data",
        },
    })
}

const deleteProfessional = async id => {
    return request({
        method: "DELETE",
        url:
            process.env.REACT_APP_VITTA_SERVICES_HOST +
            `/vittanet/professionals/${id}`,
        vtAuth: true,
    })
}

const getNfseStatus = async id => {
    return request({
        method: "GET",
        url:
            process.env.REACT_APP_VITTA_SERVICES_HOST +
            `/vittanet/professionals/${id}/nfse/status`,
        vtAuth: true,
    })
}

const syncElectronicReceipt = async (id,entity_to_sync = "zoop") => {
    return request({
        method: "POST",
        url:
            process.env.REACT_APP_VITTA_SERVICES_HOST +
            `/vittanet/professionals/sync-electronic-receipt/${id}?entity=${entity_to_sync}`,
        vtAuth: true,
    })
}
const disableSyncElectronicReceipt = async (id, entity = "zoop") => {

    return request({
        method: "POST",
        url:
            process.env.REACT_APP_VITTA_SERVICES_HOST +
            `/vittanet/professionals/sync-electronic-receipt/disable/${id}?entity=${entity}`,
        vtAuth: true,
    })
}

const getMemedInfo = async id => {
    return request({
        method: "GET",
        url:
            process.env.REACT_APP_VITTA_SERVICES_HOST +
            `/vittanet/professionals-memed/${id}`,
        vtAuth: true,
    })
}
export {
    search,
    create,
    update,
    loadDataEditform,
    deleteProfessional,
    loadDataAddform,
    getNfseStatus,
    syncTaxdocsData,
    uploadImageProfessional,
    syncElectronicReceipt,
    disableSyncElectronicReceipt,
    searchReportProfessional,
    getMemedInfo,
}
